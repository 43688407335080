// Normal fields
import AddressField from 'formbuilder/fields/AddressField';
import TextField from 'formbuilder/fields/TextField';
import TextappendField from 'formbuilder/fields/TextappendField';
import TextareaField from 'formbuilder/fields/TextareaField';
import EmailField from 'formbuilder/fields/EmailField';
import HiddenField from 'formbuilder/fields/HiddenField';
import NumberField from 'formbuilder/fields/NumberField';
import PriceField from 'formbuilder/fields/PriceField';
import PasswordField from 'formbuilder/fields/PasswordField';
import PercentageField from 'formbuilder/fields/PercentageField';
import PhoneField from 'formbuilder/fields/PhoneField';
import VatField from 'formbuilder/fields/VatField';

// Visual fields
import TitleField from 'formbuilder/fields/TitleField';
// import CardField from 'formbuilder/fields/CardField';
// import UserField from 'formbuilder/fields/UserField';
import UploadField from 'formbuilder/fields/UploadField';
import ImageField from 'formbuilder/fields/ImageField';

// Component fields
import DateField from 'formbuilder/fields/DateField';
import DatetimeField from 'formbuilder/fields/DatetimeField';
import EditorField from 'formbuilder/fields/EditorField';
import TimeField from 'formbuilder/fields/TimeField';
import ColorField from 'formbuilder/fields/ColorField'; // For now just simple input[type="color"]

// With predefined options
import RadioField from 'formbuilder/fields/RadioField';
import CheckboxField from 'formbuilder/fields/CheckboxField';
import SelectField from 'formbuilder/fields/SelectField';
import ListField from 'formbuilder/fields/ListField';

// Link Fields
import JsonLinkField from 'formbuilder/fields/JsonLinkField';
// Related module fields, with column or related with moduleitem_lnk
import SingleTagField from 'formbuilder/fields/SingleTagField';
import MultiTagField from 'formbuilder/fields/MultiTagField';

export default {
	// Normal fields
	"NoticeField": TextField,
	"AddressField" : AddressField,
	"TextField" : TextField,
	"TextappendField" : TextappendField,
	"TextareaField" : TextareaField,
	"EmailField" : EmailField,
	"HiddenField" : HiddenField,
	"NumberField" : NumberField,
	"PriceField" : PriceField,
	"EuroField" : PriceField,
	"PasswordField" : PasswordField,
	"PercentageField" : PercentageField,
	"PhoneField" : PhoneField,
	"VatField" : VatField,
	// Visual fields
	"TitleField" : TitleField,
	// "CardField" : CardField,
	// "UserField" : UserField,
	"UploadField": UploadField,
	// Component fields
	"DateField" : DateField,
	"DatetimeField" : DatetimeField,
	"EditorField" : EditorField,
	"TimeField" : TimeField,
	"ColorField" : ColorField,
	// With predefined options
	"RadioField" : RadioField,
	"CheckboxField" : CheckboxField,
	"SelectField" : SelectField,
	"ListField" : ListField,
	"ImageField" : ImageField,
	// Link fields
	"JsonLinkField" : JsonLinkField,
	// Tag fields
	"SingleTagField" : SingleTagField,
	"MultiTagField" : MultiTagField,
};