const DATABASE_NAME = "cacheDatabase";
const STORE_NAME = "cacheStore";

// Example to get data
// const fetchFieldDataWithCache = async (target) => {
// 	const data = await getJSON(target);
// 	if (data) {
// 		componentData[target] = data;
// 	}
// 	.. extra loading stuff
// };

// Example to set data
// setJSONWithExpiration(type, componentData[type], cachingSettings[type]);

const openDatabase = () => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DATABASE_NAME, 1);

		request.onupgradeneeded = (event) => {
			const db = event.target.result;
			const objectStore = db.createObjectStore(STORE_NAME, { keyPath: 'key' });
			objectStore.createIndex('expiresAt', 'expiresAt');
		};

		request.onsuccess = (event) => resolve(event.target.result);
		request.onerror = (event) => reject(event.target.error);
	});
};

// Function to set JSON data with an expiration date
const setJSONWithExpiration = async (key, data, days = 2) => {
	const db = await openDatabase();
	const transaction = db.transaction([STORE_NAME], 'readwrite');
	const objectStore = transaction.objectStore(STORE_NAME);
	const expiresAt = Date.now() + days * 24 * 60 * 60 * 1000; // 2 days from now

	const cacheData = (typeof data === "object") ? JSON.parse(JSON.stringify(data)) : data;
	objectStore.put({ key, cacheData, expiresAt });
};

// Function to get JSON data and remove it if expired
const getJSON = async (key) => {
	const db = await openDatabase();
	const transaction = db.transaction([STORE_NAME], 'readwrite');
	const objectStore = transaction.objectStore(STORE_NAME);
	const request = objectStore.get(key);
	let result = null;

	request.onsuccess = (event) => {
		const record = event.target.result;
		if (record) {
			if (record.expiresAt < Date.now()) {
				objectStore.delete(key); // Remove expired data
			} else {
				result = record.cacheData;
			}
		}
	};

	return new Promise((resolve, reject) => {
		transaction.oncomplete = () => resolve(result);
		transaction.onerror = () => reject(transaction.error);
	});
};


export { getJSON, setJSONWithExpiration };