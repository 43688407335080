<template>
    <router-view />
</template>

<script>
import { defineComponent, reactive, provide, computed } from "vue";
import { useRouter } from "vue-router";

// Our styles
import styles from 'css/app.scss';

export default defineComponent({
    name: "app",
    setup() {

        const appData = reactive({
            icons: {}
        });
        provide("appData", appData);

		const router = useRouter();
		provide("killswitch", () => {
			router.push("/manipulation");
		});
    }
});
</script>

<style>

</style>