<template>
    <ul class="block-grid messages" v-if="componentData.messages.length">
        <li v-for="message in componentData.messages">
            <div class="alert" :class="message.type">
                <Icon :name="message.type" />
                <span class="title" v-html="trans(message.title)"></span>
            </div>
        </li>
    </ul>
</template>

<script>
import {defineComponent, reactive} from "vue";

import Icon from "components/icon.vue";

// Our helpers
import trans from "helpers/_translation";

export default defineComponent({
    name: "message-overview",
    props: {},
    components: {
        Icon
    },
    setup() {

        const componentData = reactive({
            messages: []
        });

        const resetMessages = () => {
            componentData.messages = [];
        };
        const setMessage = (title, type = "error") => {
            componentData.messages.push({
                title,
                type
            });
        };

        return {
            trans,
            componentData,

            resetMessages,
            setMessage
        }
    },
});
</script>

<style lang="scss">
.messages {
    flex-direction: column;
    align-items: stretch;
    margin-top: 20/16*1rem;

    .icon:empty {
        display:none;
    }

    .alert {
        padding: 14/16*1rem;
        border: 1px solid #b6effb;
        line-height: 1.1;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 10/16*1rem;
        font-size: 14/16*1rem;
        border-radius: 5px;

        color: #055160;
        background-color: #cff4fc;

        &.success {
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }


        &.error {
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }
    }
}

</style>