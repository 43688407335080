<template>
    <div class="popup-modal" v-if="componentData.isVisible">
        <div class="popup-background" @click="handleCloseByBackground"></div>
        <div class="popup-outer">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { defineComponent, defineEmits, reactive } from "vue";

export default defineComponent({
    name: "PopupModal",
    emits: [
        "cancelmodal"
    ],
    setup(props, { emit }) {
        const componentData = reactive({
            isVisible: false
        });

        const open = () => {
            componentData.isVisible = true;
        };
        const close = () => {
            componentData.isVisible = false;
        };
        const handleCloseByBackground = () => {
            emit("cancelmodal");
        };

        return {
            handleCloseByBackground,
            componentData,
            open,
            close
        }
    }
});
</script>

<style lang="scss">
@keyframes popup-fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.popup-modal {
    position: fixed; left:0; top:0; right:0; bottom:0;
    z-index:99999;
}
.popup-outer {
    position: absolute; left:0; top:0; right:0; bottom:0;
    z-index: 2;
    pointer-events:none;

    overflow-y:auto;
    text-align:center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    .Popup {
        pointer-events: all;
        margin:0;
        animation: popup-fadein .2s;
    }
}
.popup-background {
    position:absolute; left:0; top:0; right:0; bottom:0;
    display:block;
    background:rgba(0,0,0,0.55);
    z-index:1;
    cursor:pointer;
}
</style>