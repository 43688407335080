/* Check if item is object */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/* Deep merge objects, usefull for creating fallbacks */
export function mergeDeep(target, sources) {
    if (!target) return sources;
    return mergeDeepFunction(JSON.parse(JSON.stringify(target)), sources);
}

function mergeDeepFunction(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeepFunction(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeepFunction(target, ...sources);
}

/* Convert multi dimensional object to FormData with Array structure when needed */
export function jsonToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}
