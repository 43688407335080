<template>
    <div class="element" :data-required="required" :class="{ TagField: true, [this.type] : true, error : error.error, success: !error.error && error.init, hidden: this.hidden }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />

        <input type="hidden" ref="inputRef" :name="this.name" :value="inputValue" @change="handleChange">
        <div class="dropdown" :data-name="this.name">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    name=""
                    :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
                    :value="searchValue"
                    @click="handleSearchClick"
                    @focus="handleSearchFocus"
                    @blur="handleSearchBlur"
                    @input="handleSearchInput"
                >
                <div class="input-group-append" v-if="inputValue && !searchFocus">
                    <button class="btn" type="button" @click.prevent="handleSearchReset">
                        <Icon name="close" />
                    </button>
                </div>
                <div class="input-group-append" v-if="show_dropdown">
                    <button class="btn" type="button" @click.prevent="handleDropdownHide">
                        <Icon name="up" />
                    </button>
                </div>
            </div>
            <div class="dropdown-menu show" v-if="show_dropdown">
                <ul class="block-grid one-up">
                    <li v-for="option in filter(componentData.options)">
                        <div class="dropdown-item" :class="{active: isOptionActive(option)}" @click.prevent="handleSearchSelection(option)">
                            <span class="title">{{ option.title }}</span>
                            <Icon name="close"  v-if="isOptionActive(option)"/>
                        </div>
                    </li>
                    <li v-if="!filter(componentData.options).length">
                        {{ trans("Geen opties gevonden.") }}
                    </li>
                </ul>
                <nav v-if="show_add">
                    <button type="button" class="more-inline reversed small" @click.prevent="handleSearchAdd">
                        <Icon name="plus" />
                        <span class="title">{{ trans("Aanmaken") }}</span>
                    </button>
                </nav>
            </div>
        </div>
        <div class="tags" :class="{dragging: isDragging}">
            <div class="tag" draggable="true" @dragstart="dragStart" @dragover.stop="allowDrop" @drop="drop" v-for="(id, key) in inputArray" :data-key="key">
                <span class="title">{{ current_option(id) }} <template v-if="key === 0 && this.primary_label">({{ trans(this.primary_label) }})</template></span>
                <button type="button" class="nobg" @click.stop="handleOption({id: id})">
                    <Icon name="close" />
                </button>
            </div>
        </div>

        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {defineComponent ,ref, watch, reactive, computed, inject, onMounted, onUnmounted} from "vue";

// Our form helpers
import { postDataAsync, getDataAsync } from "helpers/_api";
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";
import { observeElement } from "helpers/_observer";

import Icon from "components/icon";

import { store_entity } from "store";

export default defineComponent({
    props: mergeDeep(
        propDefaults,
        {
            primary_label: {
                type: String,
                required: false,
                default: "primair"
            }
        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement,

        Icon
    },
    setup(props, { emit }) {
        const inputRef = ref();
        const inputArray = ref([]);
        const inputValue = computed(() => {
            return inputArray.value.join(",");
        });
        const {registerValidator, validate, error} = useFieldValidation();

        const componentData = reactive({
            options: {}
        });

        const resolveOptions = (options) => {
            if (!props.required) {
                options = {
                    "": `- Selecteer een ${props.label} -`,
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.options = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            () => props.value,
            () => {
                const tmpValue = props.value || "";
                inputArray.value = tmpValue.split(",").filter(a => a !== "");
            },
            {
                immediate: true
            }
        );

        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(inputValue, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: inputValue.value
            });
        }


        const searchValue = ref("");
        const current_match = computed(() => {
            const result = Object.entries(componentData.options).find(([key, value]) => {
                return value.toLowerCase() === searchValue.value?.toLowerCase();
            });
            return  result ? {
                id: result[0],
                title: result[1]
            } : null;
        })
        const current_option = (value) => {
            return computed(() => {
                return componentData.options[value];
            }).value;
        };

        const searchModule = computed(() => {
            return props.options_object.split("::")[0].replace("Mapper", "");
        });
        const searchSelection = ref(false);
        const searchAdding = ref(false);
        const searchFocus = ref(false);
        const show_dropdown = computed(() => {
            return searchFocus.value || searchAdding.value || searchSelection.value;
        });
        const show_add = computed(() => {
            if (!props.options_object?.includes("Mapper::")) return false;
            return !current_match.value;
        });

        const isOptionActive = (option) => {
            return computed(() => {
                return inputArray.value.includes(option.id);
            }).value;
        }
        const handleOption = (option) => {
            if (isOptionActive(option)) {
                inputArray.value = inputArray.value.filter(a => {
                    return a != option.id;
                });
            } else {
                inputArray.value.push(option.id);
            }
            handleChange();
        }

        const handleSearchInput = (e) => {
            e.target.value = `${e.target.value.charAt(0).toUpperCase()}${e.target.value.slice(1)}`;
            searchValue.value = e.target.value;
        }
        const handleSearchReset = () => {
            searchValue.value = "";
        }
        const handleSearchClick = (e) => {
            e.target.setSelectionRange(0, e.target.value.length)
        }
        const handleSearchFocus = () => {
            searchFocus.value = true;
        }
        const handleSearchBlur = () => {
            setTimeout(() => {
                searchFocus.value = false;
                // if (current_match.value && !isOptionActive(option)) {
                //     handleOption(current_match.value);
                // }
                searchValue.value = "";
            }, 200);
        }
        const handleDropdownHide = () => {
            searchFocus.value = searchSelection.value = false;
        }


        const handleSearchSelection = (option) => {
            // searchSelection.value = true; // Disabled, no need to keep open atm
            handleOption(option);
        }
        const handleSearchAdd = (e) => {
            if (!searchValue.value || current_match.value || !searchModule.value) return;
            searchAdding.value = true;
            // Now post our data
            const formData = new FormData();
            formData.set("title", searchValue.value);
            postDataAsync(`/api/v1.0/${searchModule.value.toLowerCase()}`, formData).then((result) => {
                if (result.data.id) {
                    componentData.options[result.data.id] = result.data.title;
                    handleOption(result.data);
                    searchValue.value = "";
                    store_entity(searchModule.value.toLowerCase(), result);
                }
                setTimeout(() => {
                    searchAdding.value = false;
                },  500);
            });
        }

        const filter = (options) => {
            return computed(() => {
                return Object.entries(options).filter(([key, value]) => {
                    return !searchValue.value || value.toLowerCase().includes(searchValue.value?.toLowerCase());
                }).sort((a, b) => {
                    return a[1].localeCompare(b[1]);
                }).map(([id, title]) => {
                    return {
                        id,
                        title
                    };
                });
            }).value;
        }


        const killswitch = inject("killswitch");
        const handleDropdownOutside = (e) => {
            if (e.target.closest(`.dropdown[data-name='${props.name}']`)) return;
            searchFocus.value = searchSelection.value = false;
        }
        onMounted(() => {
            if (props.readonly) {
                observeElement(inputRef.value, "value", (oldValue, newValue) => {
                    if (props.value == newValue) return;
                    console.error("DOM manipulation not allowed", oldValue, newValue);
                    killswitch && killswitch();
                });
            }

            document.addEventListener("click", handleDropdownOutside);
        });
        onUnmounted(() => {
            document.removeEventListener("click", handleDropdownOutside);
        });


        let oldIndex = null;
        const isDragging = ref(false);
        const dragStart = (e) => {
            isDragging.value = true;
            oldIndex = e.target.dataset.key;
        }

        const allowDrop = (e) => {
            e.preventDefault();
        }

        const drop = (e) => {
            e.preventDefault();

            const newIndex = e.target.dataset.key;

            const tmpArray = inputArray.value;
            tmpArray.splice(newIndex, 0, tmpArray.splice(oldIndex, 1)[0]);
            inputArray.value = tmpArray;

            isDragging.value = false;
        }

        return {
            trans,
            inputValue,
            inputArray,
            inputRef,
            error,

            filter,
            componentData,
            resolveProps,
            validate,
            required,

            handleChange,

            searchValue,
            searchFocus,
            searchSelection,
            show_dropdown,
            show_add,

            isOptionActive,
            current_option,
            current_match,

            handleSearchInput,
            handleSearchClick,
            handleSearchFocus,
            handleSearchBlur,
            handleSearchReset,
            handleSearchSelection,
            handleSearchAdd,
            handleDropdownHide,

            handleOption,

            dragStart,
allowDrop,
            drop
        };
    },
});
</script>