<template>

    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <template v-if="Object.keys(componentData.options).length > 5">
            <div ref="dropdown" class="dropdown">
                <button class="btn dropdown-toggle" type="button" @click="handleDropdownClick($event)"
                        :disabled="!this.editing || this.readonly">
                    <span class="title" v-if="!this.value">{{ trans("Selecteer een ") + this.label }}</span>
                    <span class="title" v-else>{{ componentData.options[this.value] ?? this.value }}</span>
                </button>
                <div class="dropdown-menu" :class="{open: isDropdownActive}">
                    <div class="Radio_container">
                        <div class="Radio_element" v-for="(label, id) in componentData.options">
                            <input
                                type="radio"
                                :checked="input === id"
                                class="radio"
                                :class="{'readonly': this.readonly}"
                                :id="`field_${this.id}_${id}`"
                                :name="this.name"
                                :readonly="!this.editing || this.readonly"
                                :autocomplete="this.autocomplete"
                                :value="id"
                                @change="handleInputChange"
                            />
                            <label :for="`field_${this.id}_${id}`">
                                <span class="title">{{ label }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="Radio_container" :class="{float: this.float}">
                <div class="Radio_element" v-for="(label, id) in componentData.options">
                    <input
                        type="radio"
                        :checked="input === id"
                        class="radio"
                        :class="{'readonly': this.readonly}"
                        :id="`field_${this.id}_${id}`"
                        :name="this.name"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :value="id"
                        @change="handleInputChange"
                    />
                    <label :for="`field_${this.id}_${id}`">
                        <span class="title">{{ label }}</span>
                    </label>
                </div>
            </div>
        </template>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch, reactive, computed} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import {propDefaults, resolveProps} from "./_props";

// Our helpers
import {mergeDeep} from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default: false}
        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {

        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();

        const componentData = reactive({
            options: {}
        });

        const dropdown = ref();
        const isDropdownActive = ref(false);
        const catchOutsideClick = (e, dropdown) => {
            // When user clicks menu — do nothing
            if (dropdown === e.target || dropdown.contains(e.target)) return false;

            // When user clicks outside of the menu — close the menu
            if (isDropdownActive.value && dropdown !== e.target && !dropdown.contains(e.target)) return true;
        };
        const handleDropdownClick = (e) => {
            isDropdownActive.value = !isDropdownActive.value;
            const closeListener = (e) => {
                if (catchOutsideClick(e, dropdown.value)) {
                    window.removeEventListener('click', closeListener);
                    isDropdownActive.value = false;
                }
            };
            if (isDropdownActive.value) {
                window.addEventListener('click', closeListener);
            } else {
                window.removeEventListener('click', closeListener);
            }
        };

        const resolveOptions = (options) => {
            if (props.show_none_option && !props.required) {
                options = {
                    "": "Geen",
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.options = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        const handleInputChange = (e) => {
            const target = e.target;
            input.value = target.value;
            validate();

            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        return {
            componentData,
            input,
            trans,
            dropdown,
            isDropdownActive,
            handleDropdownClick,

            resolveProps,
            resolveOptions,
            error,

            handleInputChange,

            required
        };
    },
}
</script>
