<template>
    <div v-if="this.show_error_message && error.error" class="element-error">{{ trans(error.message) }}</div>
</template>

<script>
import { defineComponent } from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default defineComponent({
    name: "error-element",
    props: mergeDeep(
        propDefaults,
        {
            error: {
                type: Object,
                required: false
            }
        }
    ),
    setup(props) {
        return {
            trans
        };
    }
});
</script>

<style>
    .element-error {
        padding:2px; font-size:11px; color:red;
    }
</style>