<template>
    <div v-if="this.show_state" class="state-container" :class="componentData.state">
        <Icon v-if="componentData.state && (componentData.state !== 'error' || !this.show_error_message)" :name="componentData.state" />
    </div>
</template>

<script>
import { defineComponent, reactive, watch} from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

// Components
import Icon from "components/icon";

export default defineComponent({
    name: "state-element",
    props: {
        show_state: {
            type: Boolean,
            required: false,
            default: true
        },
        show_error_message: {
            type: Boolean,
            required: false,
            default: true
        },
        error: {
            type: Object,
            required: false
        }
    },
    components: {
        Icon
    },
    setup(props) {
        const componentData = reactive({
            state: null
        });
        watch(
            () => props.error,
            (error) => {
                if (error?.error) {
                    componentData.state = "error";
                } else if (error?.init) {
                    componentData.state = "success";
                } else {
                    componentData.state = null;
                }
            },
            {
                immediate: true,
                deep: true
            }
        );

        return {
            trans,
            componentData
        };
    }
});
</script>

<style lang="scss">
.state-container {
    position:relative; width:100%; height:0px; overflow:visible; pointer-events:none;
    .icon {
        position:absolute; right:15px; top:15px;
    }
}
.placeholder-container + .state-container {
    top:5px;
}
.SelectField .state-container {
    .icon {
        right:30px;
    }
}
</style>