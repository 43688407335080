<template>
    <div>
        404
    </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "error-404",
  components: {},
  setup() {

  },
});
</script>
