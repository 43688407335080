import { device } from "helpers/_device";

import trans from "helpers/_translation";

const defaultForms = {
	authentication: [
		{
			"type": "EmailField",
			"name": "username",
			"label": "E-mailadres",
			"required": true,
			"autocomplete": "email",
			"data": {
				"colclass": "span12"
			}

		}
	],
	hostingExtern: [
		{
			"type": "SelectField",
			"name": "account_type",
			"label": "Soort account",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{"(s)ftp":"(s)ftp","mysql":"mysql","userfiles":"userfiles"}'
			}
		},
		{
			"type": "TextField",
			"name": "domain_name",
			"label": "Domeinnaam",
			"required": true,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "TextField",
			"name": "notice",
			"label": "Onderwerp",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "ListField",
			"name": "company_id",
			"label": "Klant",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "TextField",
			"name": "username",
			"label": "Gebruikersnaam",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span6"
			}
		},
		{
			"type": "TextField",
			"name": "password",
			"label": "Wachtwoord",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span6"
			}
		},
		{
			"type": "TextareaField",
			"name": "value",
			"label": "Extra gegevens",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		}
	],
	account: [
		{
			"type": "TextField",
			"name": "hostname",
			"label": "Domeinnaam",
			"required": false,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "TextField",
			"name": "notice",
			"label": "Onderwerp",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "ListField",
			"name": "company_id",
			"label": "Klant",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "TextField",
			"name": "username",
			"label": "Gebruikersnaam",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span6"
			}
		},
		{
			"type": "TextField",
			"name": "password",
			"label": "Wachtwoord",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span6"
			}
		},
		{
			"type": "TextareaField",
			"name": "value",
			"label": "Extra gegevens",
			"required": false,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		}
	],
	alias: [
		{
			"type": "HiddenField",
			"name": "hosting_id",
			"label": "Hosting",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		},
		{
			"type": "TextappendField",
			"name": "domainalias_name",
			"label": "Domeinnaam",
			"required": true,
			"placeholder": "(ex. subsite)",
			"pattern": "^[^\\.]*$",
			"notice": ".voorbeeld.ibizz.nl",
			"data": {
				"colclass": "span12"
			}
		},
	],
	test: [
		{
			"type": "HiddenField",
			"name": "type",
			"label": "Type",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "HiddenField",
			"name": "project_id",
			"label": "Project",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		},
		{
			"type": "TextField",
			"name": "domain_name",
			"label": "Domeinnaam",
			"required": false,
			"readonly": true,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		}
	],
	staging: [
		{
			"type": "HiddenField",
			"name": "type",
			"label": "Type",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "HiddenField",
			"name": "project_id",
			"label": "Project",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		},
		{
			"type": "TextField",
			"name": "domain_name",
			"label": "Domeinnaam",
			"required": false,
			"readonly": true,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		}
	],
	production: [
		{
			"type": "HiddenField",
			"name": "type",
			"label": "Type",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "HiddenField",
			"name": "project_id",
			"label": "Project",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		},
		{
			"type": "TextField",
			"name": "domain_name",
			"label": "Domeinnaam",
			"required": false,
			"readonly": true,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		}
	],
	hosting: [
		{
			"type": "TextField",
			"name": "domain_name",
			"label": "Domeinnaam",
			"required": true,
			"placeholder": "(ex. www.ibizz.nl)",
			"pattern": "[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "type",
			"label": "Type",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span6",
				"optionsJson": '{"test":"Test","staging":"Staging","production":"Production"}'
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span6",
				"optionsJson": '{}'
			}
		}
	],
	projectEdit: [
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		},
		{
			"type": "TextField",
			"name": "bitbucket_slug",
			"label": "BitBucket slug",
			"required": true,
			"readonly": false,
			"placeholder": " ",
		},
	],
	project: [
		{
			"type": "ListField",
			"name": "company_id",
			"label": "Klant",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "projecttype_id",
			"label": "Projecttype",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span6"
			}
		},
		{
			"type": "SelectField",
			"name": "cloned_from_bitbucket_slug",
			"label": "Product",
			"required": true,
			"readonly": true,
			"placeholder": " ",
			"default_value": "wingzz-base-v3",
			"data": {
				"colclass": "span6",
				"optionsJson": '{"wingzz-base-v3":"WiNGZZ / Base v3"}'
			}
		},
		{
			"type": "TextField",
			"name": "projecttype_name",
			"label": "Anders, namelijk",
			"required": false,
			"placeholder": "Example: jaarverslag",
			"data": {
				"colclass": "span6",
				"conditions": [
					{
						"logical_operator": "AND",
						"logical_values": [
							{
								"name": "projecttype_id",
								"operator": "=",
								"value": ["8"]
							}
						]
					}
				]
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		}
	],
	product: [
		{
			"type": "SelectField",
			"name": "cloned_from_bitbucket_slug",
			"label": "Product",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "jira_issuenumber",
			"label": "Epic",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12"
			}
		},
		{
			"type": "SelectField",
			"name": "server_id",
			"label": "Server",
			"required": true,
			"placeholder": " ",
			"data": {
				"colclass": "span12",
				"optionsJson": '{}'
			}
		}
	]
};

export const getAuthenticationForm = (type = "login") => {
	const fields = [...defaultForms.authentication];
	if (type !== "forget") {
		fields.push({
			"type": "PasswordField",
			"name": "password",
			"label": "Wachtwoord",
			"required": type === "login",
			"autocomplete": "password",
			"data": {
				"colclass": "span12"
			}
		});
	}
	return fields;
};


export const getForm = (type = "account") => {
	const fields = [...(defaultForms[type] || [])];
	switch (type) {
		case 'account':
			break;
		default:

	}
	return fields;
};