// Return device based on screensize
export const device = function getDevice() {
    const screenWidth = window.screen.availWidth;
    if (screenWidth <= 767) {
        return "mobile";
    } else if (screenWidth >= 768 && screenWidth <= 1024) {
        return "tablet";
    }
    return "desktop";
}();

export const is_touch_device = function getTouchDevice() {
    if (window.navigator['msPointerEnabled'] && window.navigator["msMaxTouchPoints"] > 0) {
        return true;
    }
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}();

export const isIE = function() {
    // document.documentMode is a IE only setting.
    return document.documentMode;
}();

const userAgent = navigator.userAgent.toLowerCase();

export const isMobile = function() {
    return /iphone|ipad|ipod|android/i.test(userAgent);
}();

export const isTablet = function() {
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
}();

// Check if we are a pagebuilder
export const isBuilder = function() {
    return document.body.classList.contains("builder");
}();