<template>
    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error, success: !error.error && error.init, hidden: this.hidden }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />
        <div class="input-group">
            <input
                type="text"
                class="input"
                ref="inputRef"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
                :autocomplete="this.autocomplete"
                :maxlength="this.maxlength"
                v-model="input"
                @keyup="validate"
                @blur="handleBlur"
                @change="handleChange"
            />
            <div class="notice">
                <span class="title">{{ this.notice }}</span>
            </div>
        </div>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {defineComponent, ref, watch, computed, onMounted, inject } from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";
import { observeElement } from "helpers/_observer";

export default defineComponent({
    props: mergeDeep(
        propDefaults,
        {
            notice: {
                type: String,
                default: ""
            }
        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const inputRef = ref();
        const input = ref(props.value);
        const {registerValidator, validate, error} = useFieldValidation();

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            }
        );

        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        const handleBlur = (e) => {
            emit("blur", e);
            validate(e);
        };

        // If we got a initial value, validate instantly
        input.value && validate();

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

        const killswitch = inject("killswitch");
        onMounted(() => {
            if (props.readonly) {
                observeElement(inputRef.value, "value", (oldValue, newValue) => {
                    if (props.value == newValue) return;
                    console.error("DOM manipulation not allowed", oldValue, newValue);
                    killswitch && killswitch();
                });
            }
        });

        return {
            emit,

            inputRef,

            trans,
            input,
            error,
            resolveProps,
            validate,
            required,

            handleBlur,
            handleChange,
            placeholder
        };
    },
});
</script>