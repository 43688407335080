<template>
    <div class="element SelectField languageselect">
        <select @change="handleLanguage" class="input">
            <option v-for="(options, key) in languageOptions" :value="key" :selected="key === getLanguage()">{{ trans(options.label) }}</option>
        </select>
    </div>
</template>


<script>
import {defineComponent } from "vue";

import { languageOptions, setLanguage, getLanguage } from "config/langs";
import trans from "helpers/_translation";

export default defineComponent({
    name: "language-select",
    props: {
    },
    components: {
    },
    setup(props) {

        const handleLanguage = (e) => {
            setLanguage(e.target.value);
        };

        return {
            languageOptions,
            getLanguage,

            handleLanguage,
            trans
        };
    },
});
</script>