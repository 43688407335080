import { ref, watch } from "vue";

import { setTranslationObject } from "helpers/_translation";

export const languageOptions = {
	"nl_NL": {
		"label": "Nederlands",
		"data": {
			"Inloggen": "Inloggen",
			"E-mailadres": "E-mailadres",
			"Gebruikersnaam": "Gebruikersnaam",
			"Wachtwoord": "Wachtwoord",
			"Nederlands": "Nederlands",
			"Engels": "Engels",
			"Wachtwoord vergeten" : "Wachtwoord vergeten",
			"Wachtwoord aanvragen" : "Wachtwoord aanvragen",

			"Zoeken..." : "Zoeken...",

			"+ Toevoegen": "+ Toevoegen",
			"Klant": "Klant",
			"Anders, namelijk": "Anders, namelijk",
			"Notitie": "Notitie",
			"Annuleren": "Annuleren",

			"Product ontwikkelingen" : "Product ontwikkelingen",
			"Projecten" : "Projecten",
			"Account gegevens": "Account gegevens",

			"Filter op": "Filter op",

			"Gegevens": "Gegevens",
			"Type": "Type",
			"Onderwerp": "Onderwerp",
			"Login": "Login",
			"Aanmaken": "Aanmaken",
			"Laden...": "Laden...",
			"Opslaan": "Opslaan",
			"%s toevoegen": "%s toevoegen",
			"%s toegevoegd": "%s toegevoegd",
			"%s omgeving toevoegen": "%s omgeving toevoegen",
			"%s omgeving toegevoegd": "%s omgeving toegevoegd",
			"Domeinnaam": "Domeinnaam",
			"Ok, doorgaan!": "Ok, doorgaan!",
			"Uw aanvraag wordt in behandeling genomen.": "Uw aanvraag wordt in behandeling genomen.",

			"%s is vereist.": "%s is vereist.",
			"%s is niet juist ingevuld.": "%s is niet juist ingevuld.",
			"Vul a.u.b. een geldig emailadres in.": "Vul a.u.b. een geldig emailadres in.",
			"Een keuze voor %s is vereist.": "Een keuze voor %s is vereist.",

			"Niet gekoppeld aan een bedrijf": "Niet gekoppeld aan een bedrijf",
			"Voor dit onderdeel zijn er geen items gevonden.": "Voor dit onderdeel zijn er geen items gevonden.",

			"title_hosting": "Automatische hosting",
			"title_hostingExtern": "Externe hosting",
			"title_account": "Accountgegevens",

			"Aangemaakt door": "Aangemaakt door",
			"Aangemaakt op": "Aangemaakt op",
			"Bewerkt door": "Bewerkt door",
			"Bewerkt op": "Bewerkt op",
			"Onbekend": "Onbekend",

			"Geen resultaten gevonden": "Geen resultaten gevonden",

			"project": "Project",
			"product": "Product",
			"account": "Account",

			"Zoek naar JIRA projecten": "Zoek naar JIRA projecten",

			"'%s' gegevens zijn gekopieërd.": "'%s' gegevens zijn gekopieërd.",

			"Sluiten": "Sluiten",

			"Foutieve gegevens": "Foutieve gegevens",
			"Weet u het zeker?": "Weet u het zeker?",
			"Doorgaan": "Doorgaan",
			"Natuuuuurlijk": "Natuuuuurlijk",
			"Annuleer": "Annuleer",
			"Naaaah": "Naaaah",
			"U staat op het punt een mail te versturen die aangeeft dat de vermelde gegevens niet kloppen.": "U staat op het punt een mail te versturen die aangeeft dat de vermelde gegevens niet kloppen.",

			"Oeps! Er ging iets fout": "Oeps! Er ging iets fout",
			"Item verwijderen?": "Item verwijderen?",
			"U staat op het punt de gegevens te verwijderen.": "U staat op het punt de gegevens te verwijderen.",
			"Verwijder": "Verwijder",
			"Verwijderen": "Verwijderen"
		}
	},
	"en_GB": {
		"label": "Engels",
		"data": {
			"Inloggen": "Login",
			"E-mailadres": "Email address",
			"Gebruikersnaam": "Username",
			"Wachtwoord": "Password",
			"Nederlands": "Dutch",
			"Engels": "English",
			"Wachtwoord vergeten" : "Forgot password",
			"Wachtwoord aanvragen" : "Request password",

			"Zoeken...": "Search...",

			"+ Toevoegen": "+ Add",
			"Klant": "Client",
			"Anders, namelijk": "Otherwise, namely",
			"Notitie": "Notice",
			"Annuleren": "Cancel",

			"Product ontwikkelingen" : "Product development",
			"Projecten" : "Projects",
			"Account gegevens": "Account details",

			"Filter op": "Filter by",

			"Gegevens": "Details",
			"Type": "Type",
			"Onderwerp": "Subject",
			"Openen": "Open",
			"Login": "Login",
			"Aanmaken": "Add",
			"Laden...": "Loading...",
			"Opslaan": "Save",
			"%s toevoegen": "Add %s",
			"%s toegevoegd": "%s added",
			"%s omgeving toevoegen": "Add %s environment",
			"%s omgeving toegevoegd": "%s environment added",
			"Domeinnaam": "Domain name",
			"Ok, doorgaan!": "OK, continue!",
			"Uw aanvraag wordt in behandeling genomen.": "Your request is being processed.",

			"%s is vereist.": "%s is required.",
			"%s is niet juist ingevuld.": "%s isn't valid.",
			"Vul a.u.b. een geldig emailadres in.": "Please fill in a valid e-mailaddress",
			"Een keuze voor %s is vereist.": "A selection for %s is required.",

			"Niet gekoppeld aan een bedrijf": "Not linked to a company",
			"Voor dit onderdeel zijn er geen items gevonden.": "No items were found.",

			"title_hosting": "Automatische hosting",
			"title_hostingExtern": "Externe hosting",
			"title_account": "Account details",

			"Aangemaakt door": "Created by",
			"Aangemaakt op": "Created on",
			"Bewerkt door": "Edited by",
			"Bewerkt op": "Edited on",
			"Onbekend": "Undefined",

			"Geen resultaten gevonden": "No results found",

			"project": "Project",
			"product": "Product",
			"account": "Account",

			"Zoek naar JIRA projecten": "Search JIRA projects",

			"'%s' gegevens zijn gekopieërd.": "'%s' data is copied.",

			"Sluiten": "Close",

			"Foutieve gegevens": "Incorrect data",
			"Weet u het zeker?": "Are you sure?",
			"Natuuuuurlijk": "Suuuuuuree",
			"Doorgaan": "Go on",
			"Annuleer": "Cancel",
			"Naaaah": "Naa'aah",
			"U staat op het punt een mail te versturen die aangeeft dat de vermelde gegevens niet kloppen.": "You are about to send an email indicating that the information provided is incorrect.",


			"Oeps! Er ging iets fout": "Oops! Something went wrong",
			"Item verwijderen?": "Delete item?",
			"U staat op het punt de gegevens te verwijderen.": "You are about to delete this item.",
			"Verwijder": "Delete",
			"Verwijderen": "Delete",

			"Bedankt": "Thank you",
			"Je verzoek voor wijziging is verstuurd.": "Your request for change has been sent.",
		}
	}
};

const currentLanguage = ref(window.localStorage.getItem("country_code") ?? Object.keys(languageOptions)?.[0]);

export const setLanguage = (country_code) => {
	currentLanguage.value = country_code;
	window.localStorage.setItem("country_code", country_code);
};
export const getLanguage = () => {
	return currentLanguage.value;
};

watch(
	() => currentLanguage.value,
	(key) => {
		if (!key) return;
		setTranslationObject(languageOptions[key]?.data ?? Object.values(languageOptions)?.[0]?.data);
	},
	{
		immediate: true
	}
);
