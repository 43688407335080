<template>
    <slot name="prepend"></slot>
    <slot>
        <span class="title">{{ this.title }}</span>
    </slot>
    <slot name="append"></slot>
</template>

<script>
import { defineComponent, inject } from "vue";

import Icon from "components/icon.vue";

// Our helpers
import trans from "helpers/_translation";

export default defineComponent({
    name: "button-text",
    props: {
        title: {
            type: String,
            required: false,
            default: "Lees meer"
        },
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Icon
    },
    setup(props) {

        return {

        }
    },
});
</script>

<style>
</style>