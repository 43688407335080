<template>
    <ul class="block-grid one-up productlist">
        <li v-for="slug in Object.keys(componentData.data).sort()">
            <h3 class="h3" v-if="slug !== no_key">{{ slug.replaceAll("-", " ") }}</h3>
            <table class="table" cellspacing="0" cellpadding="0">
                <thead>
                <tr>
                    <th colspan="2"></th>
                    <th width="100">{{ trans("Test") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in componentData.data[slug]" :set="test = resolveHosting(item)">
                    <td class="td_title">
                        <a class="titlelink" :href="`https://bitbucket.org/studioibizz/${item.data.bitbucket_slug}/src`" target="_blank">
                            <span class="excerptbutton" @click.prevent="toggleExcerpt">
                                <Icon name="chevron" />
                            </span>
                            {{ item._meta.name }}
                        </a>
                        <span v-if="item.data.bitbucket_autodeploy" class="tag autodeploy">{{ trans("Autodeploy") }}</span>
                        <span class="tag wingzz" :class="resolveVersionClasses(item)" v-if="item.data.wingzz_version">{{ item.data.wingzz_version }}</span>
                        <span class="tag php" v-if="production && production.php_version">{{ production.php_version }}</span>
                        <div class="excerpt">
                            <ul class="block-grid excerptlist">
                                <li>
                                    <label>{{ trans("Aangemaakt door") }}:</label>
                                    <var>{{ item.data.creation_user ?? trans("Onbekend") }}</var>
                                </li>
                                <li>
                                    <label>{{ trans("Aangemaakt op") }}:</label>
                                    <var>{{ item.data.creation_date ?? trans("Onbekend") }}</var>
                                </li>
                            </ul>
                        </div>
                    </td>
                    <td class="td_tools" nowrap>
                        <nav class="iconav">
                            <a class="bitbucketbutton" :href="`https://bitbucket.org/studioibizz/${item.data.bitbucket_slug}/pipelines`" target="_blank" :title="trans('Open Bitbucket Pipelines')">
                                <Icon name="bitbucket" />
                            </a>
                            <button type="button" class="jirabutton" @click.prevent="handleJiraClick(item)" :title="trans('Zoek naar JIRA projecten')">
                                <Icon name="jira" />
                            </button>
                        </nav>
                    </td>

                    <td class="td_test" :data-title="trans('Test')" nowrap>
                        <nav v-if="test?.domain_name">
                            <a class="more-inline show test" :href="`//${test.domain_name}`" target="_blank">
                                <span class="title">🔗 {{ trans("Openen") }}</span>
                            </a>
                            <button type="button" class="more-inline ftp" @click.prevent="handleHostingRequest(item, resolveHosting(item))">
                                <span class="title">📄 {{ trans("Login") }}</span>
                            </button>
                        </nav>
                        <nav v-else>
                            <button type="button" class="more-inline new test" @click.prevent="handleHostingAdd(item, 'test')">
                                <span class="title">➕ {{ trans("Aanmaken")}}</span>
                            </button>
                        </nav>
                    </td>
                </tr>
                </tbody>
            </table>
        </li>
    </ul>
</template>

<script>
import {inject, reactive, watch} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";

export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    components: {
        Icon
    },
    setup(props) {

        const no_key = "zzz_no_slug";
        const componentData = reactive({
            data: {}
        });
        const sortDataByClonedFromBitbucketSlug = (data) => {
            return data.reduce(function (r, a) {
                const slug = a.data.cloned_from_bitbucket_slug || no_key;
                r[slug] = r[slug] || [];
                r[slug].push(a);
                return r;
            }, Object.create(null));
        }
        const storeDataByClonedFromBitbucketSlug = (data) => {
            componentData.data = sortDataByClonedFromBitbucketSlug(data);
        };
        watch(
            () => props.data,
            storeDataByClonedFromBitbucketSlug,
            {
                immediate: true,
                deep: true
            }
        );

        const resolveVersion = (item) => {
            const result = {
                version: item.data.wingzz_version,
                simplified: item.data.wingzz_version
            };
            if (item.data.wingzz_version) {
                result.simplified = result.simplified.substr(0, result.simplified.indexOf("."));
            }
            return result;
        };
        const resolveVersionClasses = (item) => {
            const version = resolveVersion(item);
            return {
                [version.simplified]: true
            }
        };

        const resolveHosting = (item) => {
            return item.extra.hosting["test"]?.[0] || item.extra.hosting["production"]?.[0];
        };

        const resolvePublicationType = (hosting) => {
            return hosting.domain_name.endsWith("ibizz.nl") ? "otap" : "live";
        };

        const handleHostingRequest = inject("handleHostingRequest");
        const handleHostingAdd = inject("handleHostingAdd");

        const toggleExcerpt = (e) => {
            const target = e.currentTarget || e.target;
            target.parentNode.parentNode.classList.toggle("excerpt_open");
        };

		const handleJiraClick = (item) => {
			if (item.data.jira_issuenumber) {
				window.open(`https://studioibizz.atlassian.net/browse/${item.data.jira_issuenumber}`, "_blank");
                return;
			}

			let companyName = (item.data.customer_name ?? getQuery("search") ?? "").toLowerCase();
			[" website", " webshop", " api"].forEach(key => {
				companyName = companyName.replace(key, "");
			});
			window.open(`https://studioibizz.atlassian.net/jira/projects?contains=${companyName}&selectedCategory=all&selectedProjectType=all&sortKey=name&sortOrder=ASC`, "_blank");
		};

        return {
            trans,
            componentData,

            resolveVersion,
            resolveVersionClasses,

            resolveHosting,
            resolvePublicationType,

			handleJiraClick,

            handleHostingRequest,
            handleHostingAdd,

            toggleExcerpt
        };
    },
}
</script>