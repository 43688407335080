<template>
    <popup-modal ref="popupRef" @cancelmodal="handleCancel">
        <div class="Popup Detail alert">
            <div class="heading" v-if="componentData.title">
                <h3>{{ trans(componentData.title) }}</h3>
            </div>
            <div class="inner" v-if="componentData.message">
                <div class="Message">
                    {{ trans(componentData.message) }}
                </div>
            </div>
            <div class="footer">
                <nav>
                    <button class="more" @click="handleConfirm">
                        <span class="title">{{ trans(componentData.okButton) }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import {defineComponent, reactive, ref, provide } from "vue";

import PopupModal from "components/popupModal.vue";
import trans from "helpers/_translation";

export default defineComponent({
    name: "PopupAlert",
    components: {
        PopupModal
    },
    setup() {
        const popupRef = ref();

        const componentDefaults = {
            title: 'Uw actie is succesvol verwerkt',
            message: '',
            okButton: 'Ok'
        };
        const componentData = reactive({
            ...componentDefaults
        });
        // Private variables
        const resolveData = reactive({
            resolvePromise: undefined,
            rejectPromise: undefined
        });

        const show = (opts = {}, autoclose = 3000) => {
            Object.keys(componentData).forEach(key => {
                componentData[key] = opts[key] || componentDefaults[key];
            });
            // Once we set our config, we tell the popup modal to open
            popupRef.value.open();
            if (autoclose) {
                const timeout = typeof autoclose === "number" ? autoclose : 3000;
                setTimeout(popupRef.value.close, timeout);
            }
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolveData.resolvePromise = resolve;
                resolveData.rejectPromise = reject;
            });
        };

        const handleConfirm = () => {
            popupRef.value.close();
            resolveData.resolvePromise(true);
        };

        const handleCancel = () => {
            popupRef.value.close();
            resolveData.resolvePromise(false);
        };
        provide("handleCancel", handleCancel);

        return {
            popupRef,
            trans,
            componentData,

            show,
            handleConfirm,
            handleCancel
        }
    }
});
</script>