import {createRouter, createWebHistory} from "vue-router";

import { checkLogin } from "services/JwtService";
import { getDataAsync } from "helpers/_api";
import { apiHostname } from "config";


import layout from "layout/layout.vue";

// Defaults
import error404 from "view/error/404";
import error500 from "view/error/500";
import errorManipulation from "view/error/manipulation";

// Dashboard related templates
import dashboardView from "view/dashboard/view";
import dashboardLogin from "view/dashboard/login";

const routes = [
    {
        path: "/login",
        name: "Dashboard login",
        component: dashboardLogin,
        props: {
            module: "Dashboard",
            action: "login"
        }
    },
    {
        path: "/manipulation",
        name: "manipulation",
        component: errorManipulation,
    },
    {
        path: "/",
        component: layout,
        children: [
            {
                path: "/",
                name: "Dashboard view",
                component: dashboardView,
                alias: ["/:module_access","/:module_access/:item_id/:type"],
                props: {
                    module: "Dashboard",
                    action: "view"
                }
            },
            // Default pages
            {
                path: "/404",
                name: "404",
                component: error404,
            },
            {
                path: "/500",
                name: "500",
                component: error500,
            }
        ]
    },
    {
        // the 404 route, when none of the above matches
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    saveScrollPosition: false,
    history: createWebHistory(),
    routes
});

const hideLoader = () => {
    document.getElementById("app").classList.remove("hidden");
    document.body.classList.remove("loading");
};
const checkToken = () => {
    return new Promise((resolve, reject) => {
        getDataAsync(`${apiHostname}/api/v1.0/ip`).then(resolve, reject);
    });
};

router.beforeEach((to, from, next) => {
    // Check for verification
    console.log(`Route to: "${to.path}" from: ${from.path}`);

    const isLoggedIn = checkLogin();
    if (to.path === "/manipulation") {
        next();
        hideLoader();
    } else if (to.path === "/login") {
        if (isLoggedIn) {
            checkToken().then(
                () => {
                    next({
                        path: "/"
                    });
                    hideLoader();
                },
                () => {
                    next();
                    hideLoader();
                }
            );
        } else {
            next();
            hideLoader();
        }
    } else {
        if (!isLoggedIn) {
            next({
                path: "/login",
                params: {nextUrl: to.fullPath},
            });
            hideLoader();
        } else {
            getDataAsync(`${apiHostname}/api/v1.0/ip`).then(
                () => {
                    next();
                    hideLoader();
                },
                () => {
                    next({
                        path: "/login",
                        params: {nextUrl: to.fullPath},
                    });
                    hideLoader();
                }
            );
        }
    }

    // if (to.path === "/login") {
    //     isLoggedIn && router.push("/");
    // } else {
    //     !isLoggedIn && router.push("/login");
    // }

});
export default router;