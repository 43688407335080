function formatUrl(url) {
    // Make sure the hostname and protocal is added
    if (url.startsWith("/") && !url.startsWith("//")) {
        url = window.location.protocol + "//" + window.location.hostname + url;
    }
    if (url.startsWith("//")) {
        url = window.location.protocol + url;
    }
    return url;
}
export function setUrl(url = window.location.href) {
    // Check if current history url isn't the same as the new url
    if (window.location.href === url) return;
    window.history.pushState({ path: url }, '', url);
}

// Returns array with current structure
export function getStructureObject() {
    return window.location.pathname.split('/').filter(e => e);
}

// Get a specific structure item by index position
export function getStructure(position) {
    return getStructureObject()[position] ?? null;
}

// Get a specific query item or when no key request we return the whole object
export function getQueryArray(key, url = window.location.href) {
    const urlObject = new URL(url);
    const urlParams = new URLSearchParams(urlObject.search);
    if (key) {
        return urlParams.getAll(key);
    }
    return Object.fromEntries(urlParams);
}
export function getQuery(key, url = window.location.href) {
    const urlObject = new URL(url);
    const urlParams = new URLSearchParams(urlObject.search);
    return key ? urlParams.get(key) : Object.fromEntries(urlParams);
}
// Set a specific query item or when no key request we return the whole object
export function setQuery(key, value, url = window.location.href) {
    const newurl = getUrlWithQueryItemReplaced(key, value, url);
    window.history.pushState({ path: newurl }, '', newurl);
}

// Get a specific query item or when no key request we return the whole object
export function hasQuery(key, url = window.location.href) {
    if (!key) {
        console.log("'key' for hasQuery is required");
        return false;
    }
    const urlObject = new URL(url);
    const urlParams = new URLSearchParams(urlObject.search);
    return urlParams.has(key);
}


// Get the url of the given string without the Query variables
export function getUrlFromString(url) {
    return url ? url.split("?")[0] : "";
}

// Get query items from the given string
export function getQueryFromString(base_url) {
    let url = new URL(formatUrl(base_url));
    return Object.fromEntries(url.searchParams.entries());
}


// Returns the URL with one query item added
export function getUrlWithQueryItemAdded(key, value, base_url = document.location.href) {
    let url = new URL(formatUrl(base_url));
    url.searchParams.append(key, value);
    return url.href;
}


// Returns the URL with one query item replaced (or added if it didn't yet exist)
export function getUrlWithQueryItemReplaced(key, value, base_url = document.location.href) {
    if (!value) return getUrlWithQueryItemRemoved(key, base_url);
    let url = new URL(formatUrl(base_url));
    url.searchParams.set(key, value);
    return url.href;
}


// Returns the current URL with the given query item(s) removed
export function getUrlWithQueryItemRemoved(key, base_url = document.location.href) {
    let url = new URL(formatUrl(base_url));
    url.searchParams.delete(key);
    return url.href;
}

// Returns the current URL with the data object key values set
export function getUrlWithQueryDataAdded(data, base_url = document.location.href) {
    let url = base_url;
    // Convert FormData to iteratable object or get object entries
    if (data instanceof FormData) {
        const keys = [...new Set(Array.from(data.keys()))];
        data = data.entries();
    } else {
        data = Object.entries(data);
    }
    for (let [key, values] of data) {
        url = getUrlWithQueryItemRemoved(key, url);
        if (values instanceof Array) {
            values.sort().forEach(value => {
                url = getUrlWithQueryItemAdded(key, value, url);
            })
        } else if (typeof values === "object" && values !== null) {
            Object.keys(values).forEach(range_key => {
                url = getUrlWithQueryItemReplaced(`${key}_${range_key}`, values[range_key], url);
            });
        } else {
            if (!values) continue;
            url = getUrlWithQueryItemReplaced(key, values, url);
        }
    }
    return url;
}

// Returns the URL with filters added
// Data passed should be formatted as;
// [
//      ["FieldFilter", { ... key, value ... }],
//      ["FieldFilter", { ... key, value ... }]
// ];
export function getUrlWithFiltersAdded(data, base_url = document.location.href) {
    let url = new URL(formatUrl(base_url));
    data.forEach(filter => {
        const filterArray = [];
        if (typeof filter == "string") {
            const itemJson = JSON.parse(filter);
            if (itemJson instanceof Array) {
                itemJson.forEach(subfilter => {
                    // newBody.push(decodeURI(JSON.stringify(subfilter)));
                    filterArray.push(JSON.stringify(subfilter));
                });
            } else {
                filterArray.push(filter);
            }

        } else {
            filterArray.push(JSON.stringify({...filter[1], "filter_type": filter[0]}));
        }
        filterArray.forEach(json => {
            url.searchParams.append("filter[]", json);
        });
    });
    return url.href;

}

// Returns the current language
export const language = function getLanguage() {
    return document.querySelector('body').dataset.language ?? "nl_NL";
}();