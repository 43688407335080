<template>
    <ul class="block-grid one-up accountlist">
        <li v-for="company in Object.keys(componentData.data).sort()">
            <h3 class="h3" v-if="company === no_company_key">{{ trans("Niet gekoppeld aan een bedrijf") }}</h3>
            <h3 class="h3" v-else v-html="company"></h3>
            <table class="table account" cellspacing="0" cellpadding="0">
                <thead>
                <tr>
                    <th :class="{ hassort: isSortingColumn('type', company) }" @click="setSortingColumn('type', company)">
                        {{ trans("Type") }}
                        <button v-if="isSortingColumn('type', company)" class="sorting" :class="{ active: isSortingColumn('type', company) }">
                            <Icon :name="`sort-${getSortingOrder(company)}`" />
                        </button>
                    </th>
                    <th :class="{ hassort: isSortingColumn('subject', company) }" @click="setSortingColumn('subject', company)" colspan="2">
                        {{ trans("Onderwerp")}}
                        <button v-if="isSortingColumn('subject', company)" class="sorting" :class="{ active: isSortingColumn('subject', company) }">
                            <Icon :name="`sort-${getSortingOrder(company)}`" />
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in componentData.data[company]" :set="hosting = item.extra.hosting">
                    <td class="td_type" nowrap>{{ trans(item.data.type) }}</td>
                    <td class="td_note">
                        <template v-if="hosting">
                            <a class="titlelink" :href="`//${hosting.domain_name}`" target="_blank">
                                <span>{{ hosting.domain_name }}<template v-if="item.data.notice"> - {{ item.data.notice }}</template></span>
                                <span class="tag otap" v-if="hosting.type">{{ hosting.type }}</span>
                                <span class="tag php" v-if="(item.data.type !== 'mysql') && hosting.php_version">{{ hosting.php_version }}</span>
                            </a>
                        </template>
                        <template v-else-if="item.data.type === 'other'">
                            <span class="notice" v-if="item.data.notice">{{ item.data.notice }}</span>
                        </template>
                    </td>
                    <td class="td_actions" nowrap>
                        <button type="button" class="more-inline ftp" @click.prevent="handleEdit(item, 'account')">
                            <span class="title">🖊 {{ trans("Bewerken") }}</span>
                        </button>
                        <button type="button" class="more-inline ftp" @click.prevent="handleAccountRequest(item)">
                            <span class="title">📄 {{ trans("Gegevens") }}</span>
                        </button>
                    </td>
                    <td class="td_icons" v-if="item._allow.includes('DELETE')">
                        <button type="button" class="linkbtn" @click.prevent="handleDelete($event, item.data.id, 'account')">
                            <Icon name="delete" />
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </li>
    </ul>
</template>

<script>
import {inject, watch, reactive} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {getDataAsync} from "helpers/_api";

export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    components: {
        Icon
    },
    setup(props) {

        const handleDelete = inject("handleDelete");

        const no_company_key = "zzz_no_company";
        const componentData = reactive({
            data: {}
        });
        const sortDataByCompany = (data) => {
            return data.reduce(function (r, a) {
                const company = a.extra.company?.name || no_company_key;
                r[company] = r[company] || [];

                // Duplicate of the template behaviour, needed to allow us to sort
                let subject = a.extra.hosting?.domain_name ?? "";
                if (subject === "" && a.data.type === 'other') {
                    subject = a.data.notice;
                }
                a.data.subject = subject;

                r[company].push(a);
                return r;
            }, Object.create(null));
        };
        const storeDataByCompany = (data) => {
            componentData.data = sortDataByCompany(data);
        };
        watch(
            () => props.data,
            storeDataByCompany,
            {
                immediate: true,
                deep: true
            }
        );

        const handleAccountRequest = inject("handleAccountRequest");
        const handleEdit = inject("handleEdit");

        const sortingDefaultFormat = {
            column: "default",
            order: "asc"
        };
        const sortingData = reactive({});
        const getSortingOrder = (uid) => {
            return sortingData[uid]?.order || "asc";
        };
        const setSortingColumn = (column, uid) => {
            if (!(uid in sortingData)) {
                sortingData[uid] = {...sortingDefaultFormat};
            }
            if (sortingData[uid].column !== column) {
                sortingData[uid].column = column;
            } else {
                sortingData[uid].order = sortingData[uid].order === "asc" ? "desc" : "asc";
            }
            sortBy(sortingData[uid], uid);
        };
        const isSortingColumn = (column, uid) => {
            return sortingData[uid]?.column === column;
        };
        const sortBy = (sortingObject, uid) => {
            componentData.data[uid].sort((a, b) => {
                let compareA = a.data[sortingObject.column] || "";
                let compareB = b.data[sortingObject.column] || "";
                // Sorts alphabetically
                Array.isArray(compareA) && (compareA = compareA.join(", "));
                Array.isArray(compareB) && (compareB = compareB.join(", "));
                return compareA.localeCompare(compareB);
            });
            if (sortingObject.order === "desc") {
                componentData.data[uid] = componentData.data[uid].reverse();
            }
        };


        return {
            trans,
            no_company_key,

            componentData,

            handleAccountRequest,
            handleEdit,
            handleDelete,

            setSortingColumn,
            isSortingColumn,
            getSortingOrder

        };
    },
}
</script>