import { createApp } from 'vue';

import main from "main.vue";
import router from "config/router";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import config from "config";

// Set our app
const app = createApp(main);
app.use(router);

// Configure Sentry
if (config.sentry.dsn) {
	Sentry.init({
		app,
		dsn: config.sentry.dsn,
		environment: config.sentry.env,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [
					"localhost",
					...config.sentry.hostnames,
					/^\//
				]
			})
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

	});
}

// Mount it
app.mount('#app');