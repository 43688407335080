const regexPhone = /^[0\+]{1}[0-9]{8,15}$/i;
const regexPhoneWithLandCode = /^(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{8,9}$|[0-9\-\s]{9,12}$)$/i;
const regexMail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;

export const validatePattern = function (string, pattern = "") {
    if (!pattern) return true;
    const regexPattern = new RegExp(`^${pattern}$`);
    return regexPattern.test(string);
}

const formatPhoneNumber = (number) => { 
	return number.replace(/[\s-]/g, '').replace("(0)","").replace("(", "").replace(")", "");
}

/**
 * Validate the given phone number. It must be 10 to 16 digits
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validatePhoneNumber = function (number, revertLastChar) {
    return regexPhone.test(formatPhoneNumber(number));
}

/**
 * Validate the given phone number. It must be 10 to 16 digits
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validatePhoneNumberWithLandCode = function (number, revertLastChar) {
    return regexPhoneWithLandCode.test(formatPhoneNumber(number));
}

/**
 * Validate the given number with the "11 proef"
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validate11Proof = function (number, revertLastChar) {
    var sum = 0;
    var chars = number.toString().split("");
    var length = chars.length;
    // First check if the given value contains 9 numbers
    if (length !== 9) {
        return false;
    }

    // Loop thru the chars
    for (var index in chars) {
        // Get the right weight
        var weight = length - index;
        if (weight === 1 && revertLastChar === true) {
            weight = weight * -1;
        }

        // Added the new value to the sum
        sum += chars[index] * weight;
    }

    // Returns if the sum is divisible by 11
    return sum % 11 === 0;
}

/**
 * Validate e-mail address
 *
 * @param {int} number
 * @param {string} revertLastChar
 *
 * @returns {string}
 */
export const validateEmail = function(value){
    return regexMail.test(value);
}

// Calculate password strength
export const calculatePasswordStrength = function(value) {
    let score = 0;
    const regexStrings = [
        /[a-z]+/g,
        /[A-Z]+/g,
        /[0-9]+/g,
        /\W+/g,
    ];

    regexStrings.forEach(regexString => {
        const regex = new RegExp(regexString);
        if (regex.test(value)) {
            score++;
        }
    })

    if (value.length >= 8) {
        score++;
    }

    return score;
}