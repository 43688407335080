<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <input
            v-if="!this.editing || this.readonly"
            type="text"
            class="input"
            v-model="input"
            :readonly="!this.editing || this.readonly"
        />
        <Calendar
            v-if="!(!this.editing || this.readonly)"
            :id="`field_${this.id}`"
            :name="this.name"
            v-model="input"
            dateFormat="yy-mm-dd"
            :touchUI="false"
            :showTime="false"
            :timeOnly="false"
            :disabledDates="[]"
            />

        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

// Our external components
import Calendar from 'primevue/calendar';

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        Calendar,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        return {
            trans,
            input,
            error,
            resolveProps,
            validate
        };
    },
}
</script>

<style lang="scss">
    .p-datepicker {
        background: #fff;
        box-shadow: 3px 3px 10px rgba(#000,0.3);
        padding: 10px;

        button {
            border:0; background:none transparent; font-weight:bold; color:inherit;
        }
        .p-datepicker-title {
            display: flex; flex-direction: row; justify-content: flex-start; align-items:center; gap: 10px;
        }
        .p-datepicker-prev {
            &::before {
                content:'\f104'; font-family: "FontAwesome"; display:inline-block;
             }
        }
    .p-datepicker-next {
    &::before {
         content:'\f105'; font-family: "FontAwesome"; display:inline-block;
     }
    }

        .p-datepicker-calendar th {
            text-align:center; text-transform:lowercase;
            padding:5px 0;
        }

        .p-disabled {
            opacity:0.5;
            pointer-events:none;
        }
        .p-datepicker-decade {
            font-weight:bold;
        }
        .p-yearpicker-year, .p-monthpicker-month, .p-datepicker-calendar td > span {
            padding:5px;
        }
        .p-link {
            padding:10px;
        }
        .p-highlight {
            font-weight:bold;
        }
    }
</style>