<template>
    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />
        <select
            ref="inputRef"
            :id="`field_${this.id}`"
            class="input"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :autocomplete="this.autocomplete"
            v-model="input"
            @blur="validate"
            @change="handleChange"
        >
            <template v-for="(label, id) in componentData.options">
                <option :value="id" :selected="this.value == id">{{ this.translation ? trans(label) : label }}</option>
            </template>
        </select>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch, reactive, computed, inject, onMounted} from "vue";

// External components
import Dropdown from "primevue/dropdown"

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

import { observeElement } from "helpers/_observer";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default:false}
        }
    ),
    components: {
        Dropdown,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, {emit}) {
		const inputRef = ref();
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();

        const componentData = reactive({
            options: {}
        });

        const resolveOptions = (options) => {
            if (!props.required) {
                options = {
                    "": `- Selecteer een ${props.label} -`,
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.options = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            () => props.value,
            () => {
                input.value = props.value || (Object.keys(componentData.options)[0] ?? "");
            },
            {
                immediate: true
            }
        );


        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

		const killswitch = inject("killswitch");
		onMounted(() => {
			if (props.readonly) {
				observeElement(inputRef.value, "value", (oldValue, newValue) => {
					if (props.value == newValue) return;
					console.error("DOM manipulation not allowed", oldValue, newValue);
					killswitch && killswitch();
				});
			}
		});

        return {
            trans,
            input,
            inputRef,
            error,
            componentData,
            resolveProps,
            validate,
            required,

            handleChange
        };
    },
}
</script>
