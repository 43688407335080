<template>
    <div class="element-maxlength" v-if="this.maxlength" :class="{ error: isError() }">
        {{ trans("Je hebt nog %s van %s tekens over.", componentData.charactersLeft, this.maxlength) }}
    </div>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default defineComponent({
    name: "maxlength-element",
    props: mergeDeep(
        propDefaults,
        {
            value: {
                type: String,
                required: false,
                default: ""
            }
        }
    ),
    setup(props) {

        const componentData = reactive({
            charactersLeft : props.maxlength
        });

        const errorPercentage = 15;
        const isError = (value = "") => {
            return componentData.charactersLeft < (props.maxlength / 100 * errorPercentage);
        }
        const resolveLength = (value) => {
            value = value ?? "";
            componentData.charactersLeft = (props.maxlength - value.length);
        }

        watch(
            () => props.value,
            resolveLength,
            {
                immediate: true
            }
        )

        return {
            trans,
            componentData,
            isError
        };
    }
});
</script>

<style>
</style>