import { computed, ref } from "vue";

export const locale = document.body.dataset.language?.replace("_", "-") ?? "nl-NL";
export const lang = document.body.dataset.language?.split("_")[0] ?? "nl";

export const translationObject = ref({...window.translation_object});

export const translation = {
    getText: function (text, ...args) {
        return computed(() => {
            // First get the translation if it exists
            let string = (translationObject.value && text in translationObject.value) ? translationObject.value[text] : text;
            // Loop through arguments and replace %s when possible
            for (let arg of args) {
                string = string.replace("%s", arg);
            }
            string = string.replace("[br]","<br>");
            // Return the value
            return string;
        }).value;
    }
}
export const setTranslationObject = (obj) => {
    translationObject.value = obj;
};

const intl = new Intl.DisplayNames([lang], { type: 'region' });
export const region = (code) => {
    if (!code) return "";
    return translation.getText(intl.of(code));
};
// region('US') = Verenigde Staten

export default translation.getText;