const filtersConfig = {
	color: [
		[
			"FieldFilter",
			{
				"c": "color_id",
				"v": "NULL"
			}
		]
	],
	default: [
		[
			"FieldFilter",
			{
				"c": "state",
				"v": "Online"
			}
		]
	]
};
export default filtersConfig;