<template>
    <canvas ref="canvasRef" id="manipulation"></canvas>
</template>

<script>
import {defineComponent, ref, onMounted, onUnmounted } from "vue";

export default defineComponent({
	name: "error-manipulation",
	components: {},
	setup() {
		const canvasRef = ref();

		let interval;
		onMounted(() => {
			if (!canvasRef.value) return;
			// Initialising the canvas
			const ctx = canvasRef.value.getContext('2d');

            // Setting the width and height of the canvas
			canvasRef.value.width = window.innerWidth;
			canvasRef.value.height = window.innerHeight;

            // Setting up the letters
			const letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZ'.repeat(6).split('');

            // Setting up the columns
			const fontSize = 16;
			const columns = canvasRef.value.width / fontSize;

            // Setting up the drops
			var drops = [];
			for (var i = 0; i < columns; i++) {
				drops[i] = 1;
			}

			// Setting up the draw function
			const draw = () => {
				if (!canvasRef.value) return;
				ctx.fillStyle = 'rgba(0, 0, 0, .1)';
				ctx.fillRect(0, 0, canvasRef.value.width, canvasRef.value.height);

				drops.forEach((drop, i) => {
					const text = letters[Math.floor(Math.random() * letters.length)];
					ctx.fillStyle = '#0f0';
					ctx.fillText(text, i * fontSize, drop * fontSize);
					drops[i]++;

					if (drop * fontSize > canvasRef.value.height && Math.random() > .95) {
						drops[i] = 0;
					}
				});
			};

			// Loop the animation
			interval = setInterval(draw, 20);
		});
		onUnmounted(() => {
			interval && clearInterval(interval);
        });

		return {
			canvasRef
		}
	},
});
</script>

<style>
#manipulation {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: #000;
    display: block;
}
</style>