import {reactive} from "vue";
import { getUrlWithQueryItemReplaced } from "helpers/_structure";

export const typeConfig = {
	project: {
		title: "Projecten",
		buttons: {
			add: {
				title: "+ Toevoegen",
				type: "project"
			}
		}
	},
	product: {
		title: "Product ontwikkelingen",
		buttons: {
			add: {
				title: "+ Toevoegen",
				type: "product"
			}
		}
	},
	account: {
		title: "Account gegevens",
		buttons: {
			add: {
				title: "+ Toevoegen",
				type: "hosting"
			}
		}
	}
};

export const componentSettings = reactive({
	modules: Object.keys(typeConfig),
	search_active: false,
	search_value: "",
	search_suggest: ""
});

export const setSearchUrl = (searchValue) => {
	const newUrl = getUrlWithQueryItemReplaced("search", searchValue);
	window.history.pushState({ path: newUrl }, '', newUrl);
};

export const handleReset = () => {
	componentSettings.search_value = "";
	setSearchUrl(componentSettings.search_value);
	componentSettings.search_active = false;
};