<template>
    <form class="SearchForm" :class="{ active: componentSettings.search_active }" @submit.prevent="handleSubmit">
        <div class="inner">
            <div class="left">
                <div class="input-group">
                    <input ref="searchRef"
                           @focus="handleFocus($event)"
                           @blur="handleBlur($event)"
                           @input="handleInput($event)"
                           class="input form-control" type="text" name="search" :value="componentSettings.search_value" :placeholder="trans('Zoeken...')">
                    <div class="input-group-append">
                        <button v-if="componentSettings.search_value" class="more white btn" type="button" @click.prevent="handleReset">
                            <Icon name="close" />
                        </button>
                        <button class="more white btn" type="submit" @click.prevent="handleSubmit">
                            <Icon name="search" />
                        </button>
                    </div>
                </div>

                <div class="element CheckboxField refines">
                    <div class="Checkbox_container">
                        <label class="Checkbox_element" v-for="module in Object.keys(typeConfig).reverse()">
                            <input type="checkbox" class="input checkbox" :value="module" :checked="componentSettings.modules.includes(module)" @change="handleModuleChange">
                            <span class="title">{{ trans(module) }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="right">
                <button type="button" class="more white" v-for="(value, key) in buttonConfig" @click="handleClick($event, key)">
                    <span class="title">{{ value.title }}</span>
                </button>
            </div>
        </div>
        <div class="Filters" v-if="componentSettings.search_value">

        </div>
    </form>
    <div v-if="componentSettings.search_value" class="Skipto colored bgwhite" :class="{'open' : componentData.skipto}" >
        <span class="toggle" @click="handleSkiptoVisibility()">
            <span class="title">{{ handleSkiptoText() }}</span>
        </span>
        <div class="inner">
            <div class="Heading">
                <h3 class="h3">{{ trans("Op deze pagina") }}</h3>
            </div>
            <ul class="block-grid one-up">
                <template v-for="(config, type) in typeConfig">
                    <li :class="type" v-if="!componentSettings.modules.length || componentSettings.modules.includes(type)">
                        <button @click="handleSkiptoScroll(`.section-${type}`)">
                            {{ trans(config.title) }}
                        </button>
                    </li>
                </template>
            </ul>
        </div>
    </div>
    <ul v-if="componentSettings.search_value" class="block-grid one-up typelist">
        <template v-for="(config, type) in typeConfig">
        <li :class="'section-' + type" v-if="!componentSettings.modules.length || componentSettings.modules.includes(type)">
            <div class="Heading">
                <div class="left">
                    <h3 class="h1">{{ trans(config.title) }}</h3>
                    <div class="Filters" v-if="isEnvironment(type)">
                        <Dropdown v-for="(dropdown_item, key) in filterData[type]" :classes="`tag ${dropdown_item.title.toLowerCase()}`">
                            <template #button>{{ dropdown_item.title }}</template>
                            <template #menu>
                                <button @click="handleFilter(type, key, option)" type="button" class="dropdown-item" v-for="option in dropdown_item.options" :class="{ active: dropdown_item.selected.includes(option) }">
                                    <span class="title">{{ option }}</span>
                                </button>
                                <div class="dropdown-item" v-if="!dropdown_item.options.length">
                                    <span class="title">...</span>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <div class="right">
                    <nav>
                        <button class="more" v-for="(button, key) in config.buttons" type="button" @click="handleClick(button.type, key)">
                            {{ trans(button.title) }}
                        </button>
                    </nav>
                </div>
            </div>
            <div class="resultlist" :set="items = filterByType(componentData[type], type)">
                <template v-if="items?.length">
                    <component
                        :is="`${type}Component`"
                        :data="items"
                    />
                </template>
                <template v-else-if="componentLoading[type]">
                    <div>{{ trans("Laden...") }}</div>
                </template>
                <template v-else>
                    <div>{{ trans("Voor dit onderdeel zijn er geen items gevonden.") }}</div>
                </template>
            </div>
        </li>
        </template>
    </ul>


    <PopupModal ref="accountPopupRef" @cancelmodal="handlePopupClose('account')">
        <div class="Popup Detail account">
            <div class="inner center" v-if="!tmpHosting.accounts.length">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1" v-if="resolvePopupTitle('account')" v-html="resolvePopupTitle('account')"></h1>
                        <button type="button" class="linkbtn" @click.prevent="handleAccountLink">
                            <Icon name="link" />
                        </button>
                    </div>
                </div>
                <div class="Content">
                    <p>{{ trans("Helaas! We hebben geen gekoppelde account gegevens gevonden.") }}</p>
                </div>
            </div>
            <div class="inner" v-else :set="server = resolveServer(tmpHosting.server?.server_id)">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1" v-if="resolvePopupTitle('account')" v-html="resolvePopupTitle('account')"></h1>
                        <button type="button" class="linkbtn" @click.prevent="handleAccountLink">
                            <Icon name="link" />
                        </button>
                    </div>
                    <div class="right" v-if="tmpHosting.server?.otap_prepare === 'Y' && tmpHosting.server?.type !== 'production' && allowDeleteServer">
                        <button type="button" class="more red small" @click="handleDelete($event, tmpHosting.server.id, 'hosting')">
                            <span class="title">{{ trans("Verwijder") }}</span>
                        </button>
                    </div>
                    <div class="aside" v-if="tmpHosting.server && server">
                        <span class="tag type">{{ tmpHosting.server.type }}</span>
                        <span class="tag domain_name" :title="server.data.notice">{{ server.data.domain_name }}</span>
                        <span class="tag server_type">{{ server.data.type }}</span>
                        <span class="tag php" v-if="tmpHosting.server.php_version">{{ tmpHosting.server.php_version }}</span>
                    </div>
                </div>
                <div v-if="tmpHostingCopied" class="alert alert-info">
                    {{ trans("'%s' gegevens zijn gekopieërd.", trans(tmpHostingCopied)) }}
                </div>
                <ul class="block-grid one-up accountlist">
                    <li v-for="account in tmpHosting.accounts">
                        <div class="Heading">
                            <div class="left">
                                <template v-if="tmpHosting.project?._meta?.name ?? tmpHosting.company?.name">
                                    <h3 class="h3" v-if="account.data.type === 'other' && account.data.notice" v-html="trans(`'%s' gegevens`, account.data.notice)"></h3>
                                    <h3 class="h3" v-else>
                                        {{ trans(account.data.type) }}
                                        <button v-if="!['other','mysql'].includes(account.data.type)" type="button" class="linkbtn" @click="handleCopyAccount($event, account.data.type)">
                                            <Icon name="copy" />
                                        </button>
                                    </h3>
                                </template>
                            </div>
                            <div class="right">
                                <button type="button" class="more grey small" @click="handleEdit(account, 'account')">
                                    <span class="title">{{ trans("Wijzigen") }}</span>
                                </button>
                                <button type="button" class="more grey small" @click="handleIncorrectAccount($event, account)">
                                    <span class="title">{{ trans("Foutieve gegevens")}}</span>
                                </button>
                                <button v-if="account._allow.includes('DELETE') && tmpHosting.server?.otap_prepare !== 'Y'" type="button" class="more red small" @click="handleDelete($event, account.data.id, 'account')">
                                    <span class="title">{{ trans("Verwijder") }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="Content" v-if="account.data.value" v-html="account.data.value" style="white-space: pre-wrap;"></div>
                        <table class="table_hosting">
                            <tbody>
                            <tr v-if="account.data.type !== 'other'">
                                <th>{{ trans("Server") }}</th>
                                <td><span class="copylink" @click="handleCopyClick($event, 'Server')">{{ tmpHosting.server.domain_name }}</span><Icon name="copy" /></td>
                            </tr>
                            <tr v-if="account.data.type !== 'other' || account.data.username">
                                <th>{{ trans("Gebruikersnaam") }}</th>
                                <td><span class="copylink" @click="handleCopyClick($event, 'Gebruikersnaam')">{{ account.data.username }}</span><Icon name="copy" /></td>
                            </tr>
                            <tr v-if="account.data.type !== 'other' || account.data.password">
                                <th>{{ trans("Wachtwoord") }}</th>
                                <td>
                                    <span class="swap">
                                    <span class="copylink password" @click="handlePasswordShow($event) && handleCopyClick($event, 'Wachtwoord')"><input type="password" :value="account.data.password"></span>
                                    <Icon @click="handlePasswordShow($event) && handleCopyClick($event, 'Wachtwoord')" name="eye" />
                                    <Icon name="copy" />
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="account.data.type === 'ftp' && tmpHosting.server.otap_key">
                                <th>{{ trans("SSH key")}}</th>
                                <td>
                                    <span class="copylink otap_key" @click="handleCopyClick($event, 'SSH key')">{{ tmpHosting.server.otap_key }}</span><Icon name="copy" />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>
            </div>
            <div class="footer">
                <nav>
                    <button class="more" @click="handlePopupClose('account')">
                        <span class="title">{{ trans("Sluiten") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>

    <PopupModal ref="errorPopupRef" @cancelmodal="handlePopupClose('error')">
        <div class="Popup Detail error">
            <div class="inner center">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1" v-if="resolvePopupTitle('error')">{{ resolvePopupTitle('error') }}</h1>
                    </div>
                </div>
                <div class="Content">
                    <p>{{ trans(resolvePopupContent('error')) }}</p>
                </div>
            </div>
            <div class="footer">
                <nav>
                    <button class="more" @click="handlePopupClose('error')">
                        <span class="title">{{ trans("Sluiten") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>

    <PopupModal ref="addPopupRef" @cancelmodal="handlePopupClose('add')">
        <div class="Popup Detail add">
            <div class="inner">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1">{{ resolvePopupTitle('add') }}</h1>
                    </div>
                </div>
                <MessageOverview ref="messageRef" />
                <template v-if='["hosting","hostingExtern","account"].includes(tmpAdd.type)'>
                    <div class="element RadioField">
                        <div class="Radio_container">
                            <div class="Radio_element" v-for='key in ["hosting","hostingExtern","account"]'>
                                <label>
                                    <input type="radio" class="input radio" name="add_type" :value="key" :checked="key === 'account'" @change="handleAddChange">
                                    <span class="title">{{ trans(`title_${key}`)}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </template>
                <form class="cs_form" ref="addFormRef" @submit.prevent="handleAddSubmit">
                    <Fields
                        v-if="tmpAdd.fields.length"
                        :form="form"
                        :fields="tmpAdd.fields"
                        :values="tmpAdd.values"
                        :editing="!tmpAdd.success"
                        :translate=true
                        :show_label=true
                        :show_placeholder=true
                        :show_error_message=true
                        :show_placeholder_on_input=false
                        @valueChange="handleValueChange"
                        @keypress.enter.prevent
                    />
                </form>
            </div>
            <div class="footer">
                <nav v-if="!tmpAdd.success">
                    <button class="more white" @click="handlePopupClose('add')">
                        <span class="title">{{ trans("Annuleren") }}</span>
                    </button>
                    <button class="more submit" type="submit" @click.prevent="handleAddSubmit">
                        <span class="title">{{ trans("Opslaan") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button class="more submit" @click="handlePopupClose('add')">
                        <span class="title">{{ trans("Ok, doorgaan!") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>

    <PopupModal ref="editPopupRef" @cancelmodal="handlePopupClose('edit')">
        <div class="Popup Detail add">
            <div class="inner">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1">{{ resolvePopupTitle('edit') }}</h1>
                    </div>
                </div>
                <MessageOverview ref="messageRef" />
                <template v-if='["hosting","hostingExtern","account"].includes(tmpAdd.type)'>
                    <div class="element RadioField">
                        <div class="Radio_container">
                            <div class="Radio_element" v-for='key in ["hosting","hostingExtern","account"]'>
                                <label>
                                    <input type="radio" class="input radio" name="add_type" :value="key" :checked="key === tmpAdd.type" @change="handleAddChange">
                                    <span class="title">{{ trans(`title_${key}`)}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </template>
                <form class="cs_form" ref="addFormRef" @submit.prevent="handleEditSubmit">
                    <Fields
                        v-if="tmpAdd.fields.length"
                        :form="form"
                        :fields="tmpAdd.fields"
                        :values="tmpAdd.values"
                        :editing="!tmpAdd.success"
                        :translate=true
                        :show_label=true
                        :show_placeholder=true
                        :show_error_message=true
                        :show_placeholder_on_input=false
                        @valueChange="handleValueChange"
                        @keypress.enter.prevent
                    />
                </form>
            </div>
            <div class="footer">
                <nav v-if="!tmpAdd.success">
                    <button class="more white" @click="handlePopupClose('edit')">
                        <span class="title">{{ trans("Annuleren") }}</span>
                    </button>
                    <button class="more submit" type="submit" @click.prevent="handleEditSubmit">
                        <span class="title">{{ trans("Opslaan") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button class="more submit" @click="handlePopupClose('edit')">
                        <span class="title">{{ trans("Ok, doorgaan!") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>

    <PopupModal ref="aliasoverviewPopupRef" @cancelmodal="handlePopupClose('aliasOverview')">
        <div class="Popup Detail add">
            <div class="inner">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1">{{ resolvePopupTitle('aliasOverview') }}</h1>
                    </div>
                    <div class="right">
                        <nav>
                            <button class="more" type="button" @click="handleAliasAdd(tmpAdd.item, tmpAdd.type)">
                                {{ trans("+ Toevoegen") }}
                            </button>
                        </nav>
                    </div>
                </div>
                <table v-if="componentData.alias.length > 0">
                    <tbody>
                        <tr v-for="item in componentData.alias">
                            <td>{{ trans("Alias:") }}</td>
                            <td><a target="_blank" :href="item.data.domainalias_name">{{ item.data.domainalias_name }}</a></td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <span>{{ trans("Er zijn nog geen aliassen") }}</span>
                </div>
            </div>
        </div>
    </PopupModal>

    <PopupModal ref="aliasPopupRef" @cancelmodal="handlePopupClose('alias')">
        <div class="Popup Detail add">
            <div class="inner">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1">{{ resolvePopupTitle('alias') }}</h1>
                    </div>
                </div>
                <MessageOverview ref="messageRef" />
                <form class="cs_form" ref="addFormRef" @submit.prevent="handleAddSubmit">
                    <Fields
                        v-if="tmpAdd.fields.length"
                        :form="form"
                        :fields="tmpAdd.fields"
                        :values="tmpAdd.values"
                        :editing="!tmpAdd.success"
                        :translate=true
                        :show_label=true
                        :show_placeholder=true
                        :show_error_message=true
                        :show_placeholder_on_input=false
                        @valueChange="handleValueChange"
                        @keypress.enter.prevent
                    />
                </form>
            </div>
            <div class="footer">
                <nav v-if="!tmpAdd.success">
                    <button class="more white" @click="handlePopupClose('alias')">
                        <span class="title">{{ trans("Annuleren") }}</span>
                    </button>
                    <button class="more submit" type="submit" @click.prevent="handleAddSubmit">
                        <span class="title">{{ trans("Opslaan") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button class="more submit" @click="handlePopupClose('alias')">
                        <span class="title">{{ trans("Ok, doorgaan!") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>
    <PopupModal ref="diffPopupRef" @cancelmodal="handlePopupClose('diff')">
        <div class="Popup Detail add">
            <div class="inner">
                <div class="Heading">
                    <div class="left">
                        <h1 class="h1">{{ trans("Git diff") }}</h1>
                    </div>
                </div>
                <form class="cs_form">
                    <div class="cs_formgrid">
                        <div class="span6">
                            <div class="element SelectField">
                                <label class="label">{{ trans("Vergelijk") }}</label>
                                <select class="input" v-model="componentData.diff.diffA">
                                    <option value="v9.4">v9.4</option>
                                    <option value="v9.5">v9.5</option>
                                </select>
                            </div>
                        </div>
                        <div class="span6">
                            <div class="element SelectField">
                                <label class="label">{{ trans("Met") }}</label>
                                <select class="input" v-model="componentData.diff.diffB">
                                    <option value="v9.4">v9.4</option>
                                    <option value="v9.5">v9.5</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                <table v-if="componentData.diff.data.length > 0">
                    <tbody>
                    <tr>
                        <th>{{ trans("Package") }}</th>
                        <th>{{ componentData.diff.diffA ? componentData.diff.diffA : ''}}</th>
                        <th>{{ componentData.diff.diffB ? componentData.diff.diffB : ''}}</th>
                        <th>{{ trans("Composer.json") }}</th>
                    </tr>
                    <tr v-for="item in componentData.diff.data">
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer">
                <nav>
                    <button class="more submit" @click="handleDiffOverview()">
                        <span class="title">{{ trans("Vergelijk") }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </PopupModal>
    <PopupConfirm ref="confirmPopupRef"></PopupConfirm>
    <PopupAlert ref="alertPopupRef"></PopupAlert>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, provide, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

// Components
import Fields from "formbuilder/fields.vue";
import Icon from "components/icon.vue";

// Config
import { apiHostname } from "config";

// Forms
import { useFormValidation } from "formbuilder/fields/_validation";
import { getForm } from "config/forms";

// Our helpers
import { setJSONWithExpiration, getJSON } from "helpers/_cache.mjs";
import { getDataAsync, postDataAsync, patchDataAsync, deleteDataAsync } from "helpers/_api";
import trans from "helpers/_translation";

import PopupModal from "components/popupModal.vue";
import PopupConfirm from "components/popupConfirm.vue";
import PopupAlert from "components/popupAlert.vue";

import accountComponent from "components/overview/account.vue";
import productComponent from "components/overview/product.vue";
import projectComponent from "components/overview/project.vue";
import MessageOverview from "components/overview/message.vue";

import Dropdown from "components/dropdown.vue";

import { getQuery } from "helpers/_structure";

import { componentSettings, typeConfig, setSearchUrl, handleReset } from "data.js";

export default defineComponent({
    name: "app-component",
    props: {
    },
    components: {
        Icon,
        Fields,
        MessageOverview,
        accountComponent,
        productComponent,
        projectComponent,
		Dropdown,

        PopupModal,
        PopupConfirm,
        PopupAlert
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();

        const searchRef = ref();

        const cachingSettings = {
            company: 1
        };

        const buttonConfig = {
            diff: {
                title: "🚀 Git"
            }
        };

        const suggestionData = reactive({
            product: [],
            project: [],
            account: []
        });
        const componentLoading = reactive({
            company: true,
            bitbucket: true,
            repository: true,
            projecttype: true,
            epics: true,
            server: true,
            product: true,
            project: true,
            account: true
        });
        const componentLoadingIndex = reactive({
            company: 0,
            bitbucket: 0,
            repository: 0,
            projecttype: 0,
            epics: 0,
            server: 0,
            product: 0,
            project: 0,
            account: 0
        });
		const filterData = reactive({
            product: {
				wingzz: {
					title: "WiNGZZ",
                    column: "wingzz_version",
                    options: []
                },
				php: {
					title: "PHP",
                    column: "php_version",
                    options: []
                }
            },
            project: {
				wingzz: {
					title: "WiNGZZ",
					column: "wingzz_version",
					options: []
				},
				php: {
					title: "PHP",
					column: "php_version",
					options: []
				}
			}
        });
        const componentData = reactive({
            skipto: true,
            activeSection: null,
            company: [],
            bitbucket: [],
            repository: [],
            projecttype: [],
            epics: [],
            server: [],
            product: [],
            project: [],
            account: [],
            alias: [],
            diff: {
                data: [],
                diffA: null,
                diffB: null
            },
        });

        const resolveColumnsByType = (type) => {
            switch (type) {
                case "product":
                    return ["customer_name", "bitbucket_slug", "wingzz_version"];
                case "project":
                    return ["customer_name", "bitbucket_slug", "wingzz_version"];
                case "account":
                    return ["type"];
                default:
                    return ["title"];
            }
        };
        const getFiltersByValue = (type = "project", val = componentSettings.search_value) => {
            const filters = [];
            if (!["server","bitbucket"].includes(type)) {
                filters.push([
                    "Field",
                    {
                        "c": "state",
                        "v": "Online"
                    }
                ]);
            }
            switch (type) {
                case "account":
                    val && filters.push([
                        "App\\Account\\Filters\\SearchFilter",
                        {
                            "v": val
                        }
                    ]);
                    break;
                case "server":
                    filters.push(
                        [
                            "Field",
                            {
                                "c": "hostingtype",
                                "v": resolveHostingType(tmpAdd.type)
                            }
                        ],
                        [
                            "Field",
                            {
                                "c": "state",
                                "v": "Online"
                            }
                        ],
                    );
                    break;
                case "product":
                    filters.push(
                        [
                            "Field",
                            {
                                "c": "is_fork",
                                "v": "Y"
                            }
                        ],
                        [
                            "Field",
                            {
                                "c": "jira_issuenumber",
                                "v": "",
                                "o": "<>"
                            }
                        ]
                    );
                    val && filters.push([
                        "Search",
                        {
                            "c": resolveColumnsByType(type),
                            "v": val
                        }
                    ]);
                    break;
                case "project":
                    filters.push(
                        [
                            "Field",
                            {
                                "c": "is_fork",
                                "v": "N"
                            }
                        ]
                    );
                    val && filters.push([
                        "Search",
                        {
                            "c": resolveColumnsByType(type),
                            "v": val
                        }
                    ]);
                    break;
                case "bitbucket":
                    filters.push(
                        [
                            "Field",
                            {
                                "c": "project",
                                "v": "SAAS"
                            }
                        ]
                    );
                    break;
                case "company":
                    filters.push(
                        [
                            "Field",
                            {
                                "c": "email",
                                "v": "",
                                "o": "<>"
                            }
                        ]
                    );
                    break;
            }
            return filters;
        };
        const fetchSearchSuggest = () => {
            const val = componentSettings.search_suggestion;
            getDataAsync(`${apiHostname}/api/v1.0/project?limit=5`, getFiltersByValue("project", val)).then(json => {
                suggestionData.project = json.data;
            });
            getDataAsync(`${apiHostname}/api/v1.0/account?limit=5`, getFiltersByValue("account", val)).then(json => {
                suggestionData.account = json.data;
            });
        };
        const resolveApiType = (type) => {
            switch (type) {
                case "product":
                    return "project";
                case "epics":
                    return "jira/epics";
            }
            return type;
        };
        const fetchData = (type, val, url) => {
            // Set default array
            !(type in componentData) && (componentData[type] = []);
            // Set default url, if none was passed
            const filters = [];

            if (!url) {
                componentData[type] = [];
                componentLoading[type] = true;
                componentLoadingIndex[type] += 1;
                url = `${apiHostname}/api/v1.0/${resolveApiType(type)}?limit=100`;
                filters.push(...getFiltersByValue(type, val));
            }
            const currentLoading = componentLoadingIndex[type];
            //
            getDataAsync(url, filters).then(json => {
                if (currentLoading !== componentLoadingIndex[type]) return;
                componentData[type] = [...componentData[type], ...json.data];
                // For now disabled paging, no need + it otherwise might trigger new calls while we already started new ones
                if (json._links.next) {
                    fetchData(type, val, json._links.next.href);
                } else {
                    componentLoading[type] = false;
                    if ((cachingSettings?.[type] ?? 0) > 0) {
                        setJSONWithExpiration(type, componentData[type], cachingSettings[type]);
                    }
                }
            });
        };

        const fetchSearchData = (val) => {
            ["product", "project", "account"].forEach(key => {
                fetchData(key, val);
            });
        };

        const setSearchState = () => {
            const searchQuery = getQuery("search");
            if (!searchQuery) return;
            // Set our defaults
            componentSettings.search_value = searchQuery;
            fetchSearchData(searchQuery);
            componentSettings.search_active = true;
        };

        const handleInput = (e) => {
            const target = e.currentTarget || e.target;
            componentSettings.search_suggest = target.value;
        };
        const handleFocus = () => {
            componentSettings.search_active = true;
        };
        const handleBlur = () => {
            if (componentSettings.search_value) return;
            componentSettings.search_active = false;
        };
        const handleSubmit = () => {
            componentSettings.search_value = searchRef.value.value;
            componentSettings.search_active = true;
            setSearchUrl(componentSettings.search_value);
            fetchSearchData(componentSettings.search_value);
        };

        onMounted(() => {
            setSearchState();
            fetchData("server", "");
            setTimeout(() => {
                fetchFieldDataWithCache("company");
                handleObserveSkipto();
            }, 500);
        });



        const accountPopupRef = ref();
        const errorPopupRef = ref();
        const addPopupRef = ref();
        const editPopupRef = ref();
        const aliasPopupRef = ref();
        const aliasoverviewPopupRef = ref();
        const confirmPopupRef = ref();
        const alertPopupRef = ref();
        const diffPopupRef = ref();
        const popupData = {
            account: accountPopupRef,
            error: errorPopupRef,
            add: addPopupRef,
            alias: aliasPopupRef,
            aliasOverview : aliasoverviewPopupRef,
            edit: editPopupRef,
            diff: diffPopupRef
        };
        let tmpHostingCopiedTimeout = null;
        const tmpHostingCopied = ref("");

        const messageRef = ref();
        const addFormRef = ref();

        const tmpAddFormat = {
            patch: false,
            success: false,
            type: null,
            item: null,
            fields: [],
            values: {},
            alias: false,
        };
        const tmpAdd = reactive({...tmpAddFormat});
        const resetAddRequest = () => {
            messageRef.value?.resetMessages();
            Object.assign(tmpAdd, {...tmpAddFormat});
        };

        const resetRouteParams = () => {
            // We only want to reset when item id was set
            if (!route.params.item_id) return;
            router.push("/");
        };

        const tmpHostingFormat = {
            project: {},
            company: {},
            server: {},
            accounts: [],
            error: null
        };
        const tmpHosting = reactive({...tmpHostingFormat});
        const resetHostingRequest = () => {
            Object.assign(tmpHosting, {...tmpHostingFormat});
        };
        const resolveServerDomain = () => {
            // Added function so we can edit it later on
            return tmpHosting.server.domain_name;
        };

        const handleEditPopup = (type) => {
            if (type === 'project'){
                setTimeout(() => {
                    tmpAdd.fields = getForm("projectEdit");
                },100)
            }
            handlePopupOpen("edit");
        };

        const handleHostingPopup = () => {
            handlePopupOpen("account");
            handleHostingLink();
        };
        const handleErrorPopup = (status) => {
            tmpHosting.error = status;
            handlePopupOpen("error");
        };

        const handleHostingRequest = (item, hosting) => {
            if (!hosting?.id) return;
            tmpHosting.project = item;
            tmpHosting.server = hosting;

            getDataAsync(`${apiHostname}/api/v1.0/hosting/${hosting.id}/account`).then(json => {
                tmpHosting.accounts = json.data;
                // We skip other when clicked from project item, since we don't want other information in that case.
                item && (tmpHosting.accounts = tmpHosting.accounts.filter(a => a.data.type !== "other"));
                handleHostingPopup();
            });
        };
        const handleProjectRequest = (item) => {
            const hosting = item.extra.hosting[route.params.type]?.[0];
            tmpHosting.project = item;
            tmpHosting.server = hosting;

            getDataAsync(`${apiHostname}/api/v1.0/hosting/${hosting.id}/account`).then(json => {
                tmpHosting.accounts = json.data;
                // We skip other when clicked from project item, since we don't want other information in that case.
                item && (tmpHosting.accounts = tmpHosting.accounts.filter(a => a.data.type !== "other"));
                handleHostingPopup();
            });
        };

        const handleEdit = (item, type) => {
            tmpAdd.item = item;
            tmpAdd.type = type;
            tmpAdd.patch = true;
            handleEditPopup(type);
        };

        const handleAccountRequest = (item) => {
            tmpHosting.company = item.extra.company;
            tmpHosting.server = item.extra.hosting;

            getDataAsync(`${apiHostname}/api/v1.0/account/${item.data.id}`).then(json => {
                tmpHosting.accounts = [json];
                handleHostingPopup();
            });
        };
        const handleError = (error) => {
            handleErrorPopup(error.status);
        };
        const handlePasswordShow = async (e) => {
            const target = e.currentTarget || e.target;
            const icon = target.parentNode.querySelector(".icon[name='eye']");
            const input = target.parentNode.querySelector("input");
            if (!input) return;
            input.type = "text";
            icon && icon.classList.add("hidden");
        };
        const handleHostingLink = async () => {
            let account;
            let type_found;
            ["userfiles", "ftp"].forEach(type => {
                // If no account found yet, set account if possible
                if (account) return;
                account = tmpHosting.accounts.find(a => a.data.type === type);
                type_found = type;
            });
            if (!account) return;
            const server_domain = resolveServerDomain();
            if (!server_domain) return;
            tmpHostingCopied.value = "";
            window.clearTimeout(tmpHostingCopiedTimeout);

            const url = `ftp://${account.data.username}:${account.data.password}@${server_domain}`;
            await navigator.clipboard.writeText(url);
            setTimeout(() => {
                tmpHostingCopied.value = type_found;
                tmpHostingCopiedTimeout = setTimeout(() => {
                    tmpHostingCopied.value = "";
                    tmpHostingCopiedTimeout = null;
                }, 5500);
            }, 100);
        };
        const handleCopyClick = async (e, key) => {
            const target = e.currentTarget || e.target;
            const input = target.querySelector("input");
            const val = input ? input.value : target.innerHTML.trim();
            if (!val) return;
            await navigator.clipboard.writeText(val);
            tmpHostingCopied.value = "";
            window.clearTimeout(tmpHostingCopiedTimeout);
            target.classList.add("saved");

            setTimeout(() => {
                tmpHostingCopied.value = key;
                tmpHostingCopiedTimeout = setTimeout(() => {
                    tmpHostingCopied.value = "";
                    target.classList.remove("saved");
                    tmpHostingCopiedTimeout = null;
                }, 5500);
            }, 100);
        };
        const handleCopyAccount = async (e, type) => {
            const account = tmpHosting.accounts.find(a => a.data.type === type);
            if (!account) return;
            const server_domain = resolveServerDomain();
            if (!server_domain) return;
            tmpHostingCopied.value = "";
            window.clearTimeout(tmpHostingCopiedTimeout);

            const url = `ftp://${account.data.username}:${account.data.password}@${server_domain}`;
            await navigator.clipboard.writeText(url);

            setTimeout(() => {
                tmpHostingCopied.value = type;
                tmpHostingCopiedTimeout = setTimeout(() => {
                    tmpHostingCopied.value = "";
                    tmpHostingCopiedTimeout = null;
                }, 5500);
            }, 100);

        };


        const resolvePopupTitle = (key) => {
            // key can be used for changes in behaviour
            return computed(() => {
                switch (key) {
                    case "error":
                        if (tmpHosting.error == 404) {
                            return trans("Item niet gevonden.");
                        }
                        return trans("Oeps! Er ging iets fout.");
                    case "alias":
                        if (["test", "staging", "production"].includes(tmpAdd.type)) {
                            if (tmpAdd.success) {
                                return trans(`%s alias toegevoegd`, trans(tmpAdd.type).toLowerCase());
                            }
                            return trans(`%s alias toevoegen`, trans(tmpAdd.type).toLowerCase());
                        }
                        return trans(`%s toevoegen`, trans(tmpAdd.type).toLowerCase());
                    case "aliasOverview":
                        if (["test", "staging", "production"].includes(tmpAdd.type)) {
                            return trans(`%s alias overzicht`, trans(tmpAdd.type).toLowerCase());
                        }
                        return trans(`%s overzicht`, trans(tmpAdd.type).toLowerCase());
                    case "edit":
                        if (["test", "staging", "production"].includes(tmpAdd.type)) {
                            if (tmpAdd.success) {
                                return trans(`%s omgeving gewijzigd`, trans(tmpAdd.type).toLowerCase());
                            }
                            return trans(`%s omgeving wijzigen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (["product"].includes(tmpAdd.type)){
                            return trans(`%s ontwikkeling wijzigen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (["hostingExtern"].includes(tmpAdd.type)){
                            return trans(`Externe hosting wijzigen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (["projectEdit"].includes(tmpAdd.type)){
                            return trans(`Project wijzigen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (tmpAdd.success) {
                            return trans(`%s wijzigen`, trans(tmpAdd.type).toLowerCase());
                        }
                        return trans(`%s wijzigen`, trans(tmpAdd.type).toLowerCase());
                    case "add":
                        if (["test", "staging", "production"].includes(tmpAdd.type)) {
                            if (tmpAdd.success) {
                                return trans(`%s omgeving toegevoegd`, trans(tmpAdd.type).toLowerCase());
                            }
                            return trans(`%s omgeving toevoegen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (["product"].includes(tmpAdd.type)){
                            return trans(`%s ontwikkeling toevoegen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (["hostingExtern"].includes(tmpAdd.type)){
                            return trans(`Externe hosting toevoegen`, trans(tmpAdd.type).toLowerCase());
                        }
                        if (tmpAdd.success) {
                            return trans(`%s toegevoegd`, trans(tmpAdd.type).toLowerCase());
                        }
                        return trans(`%s toevoegen`, trans(tmpAdd.type).toLowerCase());
                    case "account":
                        let fallbackTitle = "";
                        if (tmpHosting.accounts.length === 1) {
                            const tmpAccount = tmpHosting.accounts[0];
                            fallbackTitle = (tmpAccount.data.type === 'other' && tmpAccount.data.notice) ? tmpAccount.data.notice : trans(tmpAccount.data.type);
                        }
                        return tmpHosting.project?._meta?.name ?? tmpHosting.company?.name ?? fallbackTitle;
                }
                return "";
            }).value;
        };
        const resolvePopupContent = (key) => {
            // key can be used for changes in behaviour
            return computed(() => {
                switch (key) {
                    case "error":
                        return "";
                }
                return "";
            }).value;
        };
        const handlePopupOpen = (key) => {
            popupData[key].value.open();
        };
        const handlePopupClose = (key) => {
            switch (key) {
                case "alias":
                case "add":
                case "error":
                    resetRouteParams();
                    resetHostingRequest();
                case "account":
                    resetRouteParams();
                    resetHostingRequest();
                default:
                    popupData[key].value.close();
                    tmpAdd.type = null;
                    tmpAdd.alias = false;
            }
        };


        const handleHostingAdd = (item, type) => {
            resetAddRequest();
            handlePopupOpen("add");
            tmpAdd.type = type;
            tmpAdd.item = item;
        };

        const handleAliasAdd = (item,type) => {
            resetAddRequest();
            handlePopupClose('aliasOverview');
            handlePopupOpen("alias");
            tmpAdd.type = type;
            tmpAdd.item = item;
            tmpAdd.alias = true;
        };

        const handleAliasOverview = (item, type) => {
            resetAddRequest();
            handlePopupOpen("aliasOverview");
            componentData.alias = [];
            const hosting = item.extra.hosting[resolveHostingType(type)][0].id
            getDataAsync(`${apiHostname}/api/v1.0/hosting/${hosting}/hostingalias`).then(json => {
                if(json.data.length > 0){
                    componentData.alias = json.data;
                }
            });

            tmpAdd.type = type;
            tmpAdd.item = item;
        };

        const handleDiffOverview = () => {
            componentData.diff.data = [];
            getDataAsync(`${apiHostname}/api/v1.0/git`, [
                [
                    "FieldFilter",
                    {
                        "c": "source",
                        "v": componentData.diff.diffA
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "c": "destination",
                        "v": componentData.diff.diffB
                    }
                ]
            ]).then(json => {
                if(json.data.length > 0){
                    componentData.diff.data = json.data;
                }
            });
        };

        provide("handleHostingRequest", handleHostingRequest);
        provide("handleAccountRequest", handleAccountRequest);
        provide("handleEdit", handleEdit);
        provide("handleHostingAdd", handleHostingAdd);
        provide("handleAliasAdd", handleAliasAdd);
        provide("handleAliasOverview", handleAliasOverview);

        const handleAdd = (type, key) => {
            resetAddRequest();
            handlePopupOpen(key);
            tmpAdd.type = type;
        };

        const isTestEnvironment = () => {
            return ["test","staging"].includes(tmpAdd.type);
        };
        const resolveHostingType = (type) => {
            if (tmpAdd.type === "project") {
                return "production";
            } else if (tmpAdd.type === "product") {
                return "test";
            }
            return type;
        }
        const filterServers = (a, type = "production") => {
            if (isTestEnvironment() && !a.data.domain_name.startsWith("otap")) {
                return false;
            }
            if (a.data.type !== resolveHostingType(type)) {
                return false;
            }
            return a.data.state === "Online";
        };
        const formatServers = (r, e) => {
            r[e.data.id] = `${e.data.domain_name} - ${e.data.notice}`;
            if (e.data.default === "Y") {
                // Set the default value
                tmpAdd.values.server_id = e.data.id;
            }
            return r;
        };
        const resolveDomainName = (project_id, server_id) => {
            const server = componentData.server.find(a => `${a.data.id}` === `${server_id}`);
            if (!server || !server.data?.domain_name || !tmpAdd.item) return "";

            let domain_name = server.data.domain_name;
            const bitbucket_slug = tmpAdd.item.data.bitbucket_slug;
            if (isTestEnvironment() && domain_name.startsWith("otap") && domain_name.endsWith("ibizz.nl")) {
                domain_name = `${tmpAdd.type}.ibizz.nl`;
            }
            return !bitbucket_slug ? trans("Onbekend") : `${bitbucket_slug}.${domain_name}`;
        };

        const initAddValues = () => {
            const result = {
                type: tmpAdd.type,
                project_id: tmpAdd?.item?.data?.id ?? null
            };

            tmpAdd.values = Object.assign(result, tmpAdd.values);
            tmpAdd.values.domain_name = resolveDomainName(tmpAdd.values.project_id, tmpAdd.values.server_id);

            if(tmpAdd.item){
                tmpAdd.fields.forEach((field, key) => {
                    if (tmpAdd.item.data.bitbucket_slug && field.name === "bitbucket_slug") {
                        tmpAdd.values[field.name] = tmpAdd.item.data.bitbucket_slug;
                    }
                    if (tmpAdd.item.data[field.name]) {
                        tmpAdd.values[field.name] = tmpAdd.item.data[field.name];
                    }
                });
            }

            watch(
                () => tmpAdd.values.projecttype_id,
                (projecttype_id) => {
                    if (!projecttype_id) return;
                    getDataAsync(`${apiHostname}/api/v1.0/projecttype/${projecttype_id}/server`, [
                        [
                            "Field",
                            {
                                "c": "hostingtype",
                                "v": resolveHostingType(tmpAdd.type)
                            }
                        ],
                        [
                            "Field",
                            {
                                "c": "state",
                                "v": "Online"
                            }
                        ],
                        [
                            "Field",
                            {
                                "c": "favorite",
                                "v": "Y"
                            }
                        ],
                    ]).then(json => {
                        const field = tmpAdd.fields.find(a => a.name === "server_id");
                        if (!field) return;
                        // Set our servers based on the projecttype provided
                        const servers = json.data.filter(a => filterServers(a, tmpAdd.type));
                        const options = servers.reduce(formatServers, {});
                        field.data.optionsJson = JSON.stringify(options);
                    })
                },
                {
                    immediate: true
                }
            );

            watch(
                () => componentData.server,
                (data) => {
                    if (!data.length) return;
                    const field = tmpAdd.fields.find(a => a.name === "server_id");
                    if (!field) return;

                    // Set our servers based on the projecttype provided
                    const servers = componentData.server.filter(a => a.data.domain_name.startsWith("otap"));
                    const options = servers.reduce(formatServers, {});
                    field.data.optionsJson = JSON.stringify(options);
                    // set first server as default
                    tmpAdd.values.server_id = field?.default_value ?? Object.keys(options)[0]
                },
                {
                    immediate: true
                }
            );
        };

        const resolveOptionsJson = (data, key_value = "id", key_title = "name") => {
            const values = data.reduce((r, e) => {
                r[e.data[key_value]] = `${e.data[key_title]}`;
                return r;
            }, {});
            return JSON.stringify(values);
        };

        watch(
            () => tmpAdd.alias,
            async () => {
                tmpAdd.values = [];
                tmpAdd.fields = [];
                if(tmpAdd.alias){
                    tmpAdd.fields = getForm("alias");
                    tmpAdd.fields.forEach((field, key) => {
                        const domain = tmpAdd.item.extra.hosting[tmpAdd.type][0].domain_name;
                        if (field.name === "domainalias_name" && domain) {
                            field.notice = "." + domain;
                        }
                        if (field.name === "hosting_id") {
                            tmpAdd.values.hosting_id = tmpAdd.item?.extra?.hosting[tmpAdd.type][0].id;
                        }
                    });
                    initAddValues();
                    return;
                }
            }
        );

        watch(
            () => tmpAdd.type,
            async (tmpType) => {
                if (!tmpType) return;
                tmpAdd.values = [];
                await (addFormRef.value && addFormRef.value.reset());
                tmpAdd.fields = getForm(tmpType);
                tmpAdd.fields.forEach((field, key) => {
                    if (field.name === "domain_name") {
                        setTimeout(() => {
                            tmpAdd.values.domain_name = resolveDomainName(tmpAdd.values.project_id, tmpAdd.values.server_id);
                        },500);
                    }
                    if (field.name === "company_id") {
                        tmpAdd.values.company_id = "";
                        tmpAdd.fields[key].data.optionsJson = resolveOptionsJson(componentData.company, "id", "name");
                    }
                });
                initAddValues();
            }
        );


		const isEnvironment = (type) => {
			return ["product", "project"].includes(type)
		};

        const resolveFieldNames = (target) => {
            const fieldNames = [
                `${target}_id`
            ];
            if (target === "epics") {
                fieldNames.push("jira_issuenumber");
            } else if (target === "bitbucket") {
                fieldNames.push("cloned_from_bitbucket_slug")
            }
            return fieldNames;
        };
        const resolveOptionKey = (target, item) => {
            if (target === "bitbucket") {
                return item.data.slug;
            }
            if (target === "epics") {
                return item.data.number;
            }
            return item.data.id;
        };
        const resolveOptionValue = (target, item) => {
            if (target === "bitbucket") {
                return item.data.name;
            }
            return item.data.name || item.data.title;
        };
        const fetchFieldData = (target => {
            fetchData(target, "");
            // Check if finished loading
            watch(
                () => componentLoading[target],
                (loading) => {
                    if (loading) return;
                    tmpAdd.fields.forEach((field, key) => {
                        if (!resolveFieldNames(target).includes(field.name)) return;
                        const values = componentData[target].reduce((r, e) => {
                            r[resolveOptionKey(target, e)] = resolveOptionValue(target,e);
                            return r;
                        }, {});
                        tmpAdd.values[field.name] = "";
                        tmpAdd.fields[key].data.optionsJson = JSON.stringify(values);
                        if (field.name === "company_id") return;
                        tmpAdd.values[field.name] = field?.default_value ?? Object.keys(values)[0];
                    });
                },
                {
                    immediate: true
                }
            )
        });
        const fetchFieldDataWithCache = async (target) => {
            const data = await getJSON(target);
            if (data) {
                componentData[target] = data;
                componentLoadingIndex[target] += 1;
                return;
            }
            fetchFieldData(target);
        };
        const handleClick = (type, key) => {
            switch (key) {
                case "diff":
                    // Open url in new window
                    handlePopupOpen("diff");
                    // window.open("https://development.ibizz.nl/1/login/git/diff", '_blank').focus();
                case "add":
                    handleAdd(type, key);
                    if (type === "product") {
                        fetchFieldData("bitbucket");
                        fetchFieldData("epics");
                    }
                    if (type === "project") {
                        fetchFieldDataWithCache("bitbucket");
                    }
                    if (isEnvironment(type)) {
                        fetchFieldData("projecttype");
                    }
                default:
                    console.log(`Clicked ${type} ${key}`);
            }
        };


        const form = useFormValidation();
        const handlePostForm = (formData) => {
            return new Promise(resolve => {
                let endpoint = tmpAdd.type;
                if (["test","staging","production"].includes(tmpAdd.type)) {
                    // const domain_name = formData.get("domain_name");
                    // console.log("Note: domain_name has been removed from FormData, since it will be set by PHP at the moment", domain_name);
                    // formData.delete("domain_name");

                    if (tmpAdd.alias) {
                        endpoint = "hostingalias";
                        formData.forEach((value, key) => {
                            if (key === "domainalias_name") {
                                const domain = tmpAdd.item.extra.hosting[tmpAdd.type][0].domain_name;
                                formData.set(key, `${value}.${domain}`);
                            }
                        });
                    } else {
                        endpoint = "hosting";
                    }
                } else if (tmpAdd.type === "account") {
                    formData.set("type", "other");
                } else if (tmpAdd.type === "project") {
                    const companyId = formData.get("company_id");
                    const companyItem = componentData.company.find(a => `${a.data.id}` === companyId);
                    if (!companyItem) return;
                    formData.set("customer_name", companyItem.data.name);
                } else if (tmpAdd.type === "projectEdit") {
                    endpoint = "project";
                }

                if(tmpAdd.patch) {
                    patchDataAsync(`${apiHostname}/api/v1.0/${endpoint}/${tmpAdd.item.data.id}`, formData).then(json => {
                        resolve(json);
                    });
                } else{
                    postDataAsync(`${apiHostname}/api/v1.0/${endpoint}`, formData).then(json => {
                        resolve(json);
                    });
                }
            });
        };

        const handleForm = (type) => {
            return new Promise((resolve, reject) => {
                const validationPromises = [
                    form.handleSubmit()
                ];
                Promise.all(validationPromises).then((results) => {
                    const formData = new FormData(addFormRef.value);
                    handlePostForm(formData).then(resolve);
                });
            });
        };
        const handleAddSubmit = (e) => {
            handleForm().then(() => {
                tmpAdd.success = true;
                messageRef.value.setMessage("Uw aanvraag wordt in behandeling genomen.", "success");

				setTimeout(() => {
					// Window reload
					window.location.reload();
				}, 2500);
            });
        };

        const handleEditSubmit = (e) => {
            handleForm('patch').then(() => {
                tmpAdd.success = true;
                messageRef.value.setMessage("Uw gegevens worden bijgewerkt.", "success");

                setTimeout(() => {
                    // Window reload
                    window.location.reload();
                }, 2500);
            });
        };

        const handleAddChange = (e) => {
            const target = e.currentTarget || e.target;
            if (!target.checked) return;
            // resetAddRequest();
            tmpAdd.type = target.value;
        };

		const filterBySelection = (data, type) => {
            return computed(() => {
				let result = [...data];
				if (filterData[type]) {
					Object.values(filterData[type]).forEach(filter => {
						if (!filter.selected.length) return;
						result = result.filter(a => {
							let exists = false;
							filter.selected.forEach(selection => {
								if (filter.column === "php_version") {
									Object.values(a.extra.hosting).forEach((hosting) => {
										if ((hosting[0]?.[filter.column] ?? "").startsWith(selection)) {
                                            exists = true;
                                        }
									});
                                    return exists;
                                }
								if (a.data[filter.column]?.startsWith(selection)) {
									exists = true;
                                }
                            });
							return exists;
                        });
                    });
                }
				return result;
			}).value;
        };
        const filterByType = (data, type) => {
			return computed(() => {
				switch (type) {
					case "account":
						return data.filter(a => {
							if (!a.extra.hosting) return true;
							return (a.extra.hosting.project_id === null);
						});
				}
				return filterBySelection(data, type);
            }).value;
        };

        const handleAccountLink = async () => {
            let url = "";
            let target = "";
            if (tmpHosting.project?.data) {
                url = `//${document.location.host}/project/${tmpHosting.project?.data.id}/${tmpHosting.server.type}`;
                target = "project";
            } else {
                const tmpId = tmpHosting.accounts[0]?.data.id;
                if (!tmpId) return;
                url = `//${document.location.host}/account/${tmpId}`;
                target = "account";
            }
            if (!url) return;

            tmpHostingCopied.value = target;
            window.clearTimeout(tmpHostingCopiedTimeout);

            await navigator.clipboard.writeText(url);
            tmpHostingCopiedTimeout = setTimeout(() => {
                tmpHostingCopied.value = "";
                tmpHostingCopiedTimeout = null;
            }, 4500);
        };

        watch(
            () => route.params,
            (params) => {
                if (params.module_access === "account" && params.item_id) {
                    getDataAsync(`${apiHostname}/api/v1.0/${params.module_access}/${params.item_id}`)
                        .then(
                            handleAccountRequest,
                            handleError
                        );
                } else if (params.module_access === "project" && params.item_id && params.type) {
                    getDataAsync(`${apiHostname}/api/v1.0/${params.module_access}/${params.item_id}`)
                        .then(
                            handleProjectRequest,
                            handleError
                        );
                } else if (params.module_access) {
                    componentSettings.modules = params.module_access.split(",");
                }
            },
            {
                immediate: true
            }
        );

        const handleModuleChange = (e) => {
            const target = e.currentTarget || e.target;
            if (target.checked) {
                !componentSettings.modules.includes(target.value) && componentSettings.modules.push(target.value);
            } else {
                componentSettings.modules = componentSettings.modules.filter(a => a !== target.value);
            }
            router.push(`/${componentSettings.modules.join(",")}?search=${componentSettings.search_value}`);
        };

        const resolveServer = (server_id) => {
            return computed(
                () => {
                    return componentData.server.find(a => `${a.data.id}` == `${server_id}`);
                }
            ).value;
        };

        const handleIncorrectAccount = (e, account) => {
            confirmPopupRef.value.show({
                message: trans("U staat op het punt een mail te versturen die aangeeft dat de vermelde gegevens niet kloppen."),
                okButton: trans("Natuuuuurlijk"),
                cancelButton: trans("Naaaah")
            }).then((state) => {
                if (!state) return; // canceled
                patchDataAsync(`${apiHostname}/api/v1.0/account/${account.data.id}/incorrect`, {}).then(() => {
					confirmPopupRef.value.hide();
                    alertPopupRef.value.show({
                        title: `${trans("Bedankt")}!`,
                        message: trans("Je verzoek voor wijziging is verstuurd."),
                        okButton: trans("Bedankt")
                    }, 5000);
                });
            });
        };

        const handleDelete = (e, item_id, module_access) => {
            if (!item_id || !module_access) {
                console.error("no module_access and item_id passed");
                return;
            }
            confirmPopupRef.value.show({
                title: trans("Item verwijderen?"),
                message: trans("U staat op het punt de gegevens te verwijderen."),
                okButton: trans("Verwijder"),
                cancelButton: trans("Annuleer")
            }).then((state) => {
                if (!state) return; // Canceled
                deleteDataAsync(`${apiHostname}/api/v1.0/${module_access}/${item_id}`).then(
                    () => {
						confirmPopupRef.value.hide();
                        alertPopupRef.value.show({
                            title: trans("Het item is verwijderd"),
                            message: trans(""),
                            okButton: trans("Doorgaan")
                        }, 5000);
						setTimeout(() => {
							// Window reload
                            window.location.reload();
						}, 1500);
                    },
                    () => {
                        alertPopupRef.value.show({
                            title: trans("Oeps! Er ging iets fout"),
                            message: trans(""),
                            okButton: trans("Doorgaan")
                        }, 5000);
                    }
                );
            });
        };

        provide("confirmPopupRef", confirmPopupRef);
        provide("alertPopupRef", alertPopupRef);
        provide("handleDelete", handleDelete);

        const handleValueChange = (data) => {
            if (data.name !== 'server_id') return;
            tmpAdd.values.domain_name = resolveDomainName(tmpAdd.values.project_id, data.value);
        };

		const allowDeleteServer = computed(() => {
			let result = true;
            tmpHosting.accounts.forEach(a => {
				if (!a._allow.includes("DELETE")) {
					result = false;
                }
            });
			return result;
        });


		const formatVersion = (version) => {
			if (!version) return "";
            return version.replace(/(\d+\.\d+)(\.[0-9x]+)(-dev)?/, '$1');
        };
		const filterOptions = (filter_type, filter_column) => {
			const options = [];
			componentData[filter_type].forEach((item) => {
				if (filter_column === "php_version") {
					Object.values(item.extra.hosting).forEach((hosting) => {
						let val = hosting[0]?.[filter_column];
						val && options.push(formatVersion(val));
					});
				} else {
					let val = item.data[filter_column];
					val && options.push(formatVersion(val));
				}
			});

			return [...new Set(options)].sort().reverse();
        };
		const handleFilter = (environment_type, filter_key, filter_value) => {
			if (filterData[environment_type][filter_key].selected.includes(filter_value)) {
                filterData[environment_type][filter_key].selected = filterData[environment_type][filter_key].selected.filter(a => a !== filter_value);
            } else {
				filterData[environment_type][filter_key].selected.push(filter_value);
            }
        };
		Object.keys(filterData).forEach(environment_type => {
			watch(
				() => componentData[environment_type],
				() => {
					Object.keys(filterData[environment_type]).forEach(filter_key => {
						filterData[environment_type][filter_key].selected = [];
						filterData[environment_type][filter_key].options = filterOptions(environment_type, filterData[environment_type][filter_key].column);
                    });
                },
				{
					immediate: true,
                    deep: true
                }
            );
        });

        const handleSkiptoVisibility = () => {
            componentData.skipto = !componentData.skipto;
        }

        const handleSkiptoText = () => {
            return componentData.skipto ? "Sluiten" : "Openen";
        };

        const handleSkiptoScroll = (target) => {
            const element = document.querySelector(target);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        };

        const handleObserveSkipto = () => {
            Object.keys(typeConfig).forEach((key) => {
                const target = ".section-" + key;
                const targetButton = ".Skipto ." + key;
                const button = document.querySelector(targetButton);

                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (button) {
                            if (entry.isIntersecting) {
                                button.classList.add("active");
                            } else {
                                button.classList.remove("active");
                            }
                        }
                    });
                });

                const element = document.querySelector(target);
                if (element) {
                    observer.observe(element);
                }
            });
        }

        return {
            trans,

            // Reference
            searchRef,

            // Configs
            buttonConfig,
            typeConfig,

            // Data
            componentLoading,
            componentSettings,
            componentData,

            filterByType,

			isEnvironment,

            handleInput,
            handleClick,
            handleReset,
            handleSubmit,

            handleFocus,
            handleBlur,
            handleValueChange,

            handleCopyClick,
            handleCopyAccount,
            handlePasswordShow,
            handleDiffOverview,

            editPopupRef,
            accountPopupRef,
            confirmPopupRef,
            alertPopupRef,
            errorPopupRef,
            addPopupRef,
            diffPopupRef,
            aliasPopupRef,
            aliasoverviewPopupRef,
            resolvePopupTitle,
            resolvePopupContent,
            handlePopupClose,

            tmpHosting,
            tmpHostingCopied,

            tmpAdd,
            form,
            messageRef,
            addFormRef,
            handleAliasAdd,
            handleEditSubmit,
            handleAddSubmit,
            handleAddChange,

            handleAccountLink,
            handleModuleChange,

            resolveServer,

            handleEdit,
            handleIncorrectAccount,
            handleDelete,

            allowDeleteServer,

			filterData,
			handleFilter,

            handleSkiptoVisibility,
            handleSkiptoText,
            handleSkiptoScroll,
        }
    },
});
</script>

<style>
</style>