<template>
    <div v-if="this.show_placeholder && this.show_placeholder_on_input" class="placeholder-container" :class="{ active: this.value }">
        <div class="placeholder" v-if="this.value">{{ formatPlaceholder(this.placeholder) }}</div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default defineComponent({
    name: "placeholder-element",
    props: mergeDeep(
        propDefaults,
        {
            value: {
                type: String,
                required: false,
                default: ""
            },
            error: {
                type: Object,
                required: false
            }
        }
    ),
    setup(props) {

        const formatPlaceholder = (text) => {
            if (props.translate) {
                text = trans(text);
            }
            if (props.required) {
                text += " *";
            }
            return text;
        }

        return {
            trans,
            formatPlaceholder
        };
    }
});
</script>

<style lang="scss">
.placeholder-container {
    position:relative; width:100%; height:0px; overflow:visible; pointer-events:none;
    .placeholder {
        position:absolute; left:15px; top:5px; opacity:0.6; font-size:12px;
    }
}
</style>