<template>
    <header id="Header">
        <Logo @click.prevent="handleClick" />
    </header>
</template>

<script>
import {defineComponent } from "vue";
import { useRouter } from "vue-router";

// Components
import Logo from "elements/logo";

import { componentSettings, handleReset } from "data.js";

export default defineComponent({
    name: "headerElement",
    components: {
        Logo
    },
    setup() {

        const handleClick = () => {
            handleReset();
        }

        return {
            handleClick
        }
    },
});
</script>
