<template>
    <headerElement></headerElement>
    <div class="App" :class="componentData.name">
        <router-view />
    </div>
    <footerElement></footerElement>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import headerElement from "layout/header";
import footerElement from "layout/footer";

export default defineComponent({
    name: "Layout",
    components: {
        headerElement,
        footerElement
    },
    setup() {
        // Our defaults
        const route = useRoute();

        // Define our parameters
        const componentData = reactive({
            name: route.name
        });

        watch(
            () => route.name,
            (name) => {
                componentData.name = name;
            }
        );

        return {
            componentData
        };
    },
});
</script>
