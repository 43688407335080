<template>
    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />

        <input type="hidden" :name="this.name" v-model="input" />
        <div class="link_container">
            <div class="link" v-for="link in componentData.links">
                <div class="left">
                    <button type="button" class="delete nobg" @click.prevent="handleDelete(link)">
                        <Icon name="remove" />
                    </button>
                    <select
                        class="input"
                        name=""
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        @change="handleChange($event, link, 'module')"
                    >
                        <template v-for="(label, key) in componentData.modules">
                            <option :value="key" :selected="link.module === key">{{ trans(label) }}</option>
                        </template>
                    </select>
                </div>
                <div class="right">
                    <input
                        type="text"
                        class="input"
                        name=""
                        v-if="link.module === 'Mailto'"
                        :value="link.url"
                        :placeholder="trans('Vul het emailadres in')"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        @input="handleChange($event, link, 'mailto')"
                    />
                    <input
                        type="text"
                        class="input"
                        name=""
                        v-if="link.module === 'Url'"
                        :value="link.url"
                        :placeholder="trans('Vul een URL in')"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        @input="handleChange($event, link, 'url')"
                    />
                    <select
                        class="input"
                        name=""
                        v-if="!(['Url','Mailto'].includes(link.module)) && (link.module !== '')"
                        :placeholder="trans('Maak a.u.b. uw keuze')"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        @change="handleChange($event, link, 'item_id')"
                    >
                        <template v-for="(label, key) in items(link)">
                            <option :value="key" :selected="link.item_id === key">{{ trans(label) }}</option>
                        </template>
                    </select>

                    <input
                        type="text"
                        class="input"
                        name=""
                        v-if="link.module"
                        :value="link.text"
                        :placeholder="trans('Knoptekst')"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        @input="handleChange($event, link, 'text')"
                    />
                </div>
            </div>
        </div>
        <nav>
            <button type="button" class="more-inline reversed small" @click.prevent="handleNew">
                <Icon name="plus" />
                <span class="title">{{ trans("Extra verwijzing toevoegen") }}</span>
            </button>
        </nav>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch, reactive, computed, inject, onMounted} from "vue";

// External components
import Dropdown from "primevue/dropdown"

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

import { observeElement } from "helpers/_observer";

import { getDataWithoutLimit  } from"helpers/_api";

import Icon from "components/icon.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default:false}
        }
    ),
    components: {
        Dropdown,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement,
        Icon
    },
    setup(props, {emit}) {
        const moduleRef = ref();
        const inputRef = ref();
        const module = ref("");
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();

        const defaultLink = {
            module: "",
            url: "",
            item_id: ""
        }
        const componentData = reactive({
            links: [
                {...defaultLink}
            ],
            modules: {},
            items: {}
        });

        const resolveOptions = (options) => {
            if (!props.required) {
                options = {
                    "": "",
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.modules = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        const getLinkItems = (link) => {
            if (link.module && !(["Url","Mailto"].includes(link.module)) && !(link.module in componentData.items)) {
                componentData.items[link.module] = [];
                getDataWithoutLimit(`/api/v1.0/${link.module.toLowerCase()}`, [], componentData.items, link.module);
            }
        }

        watch(
            () => props.value,
            () => {
                let links;
                if (props.value && props.value != "") {
                    try {
                        links = JSON.parse(props.value);
                    } catch (e) {
                        console.error("Invalid JSON", e);
                    }
                }
                if (links && links.length) {
                    componentData.links = links;
                    links.forEach(getLinkItems);
                } else {
                    componentData.links = [
                        {...defaultLink}
                    ];
                }
            },
            {
                immediate: true
            }
        );


        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const killswitch = inject("killswitch");
        onMounted(() => {
            if (props.readonly) {
                observeElement(inputRef.value, "value", (oldValue, newValue) => {
                    if (props.value == newValue) return;
                    console.error("DOM manipulation not allowed", oldValue, newValue);
                    killswitch && killswitch();
                });
            }
        });

        const handleChange = (e, link, key) => {
            const orgValue = e.target.value;
            if (key === "url" && orgValue.length > 7) {
                if (!orgValue.startsWith("https://")
                    && !orgValue.startsWith("http://")) {
                    e.target.value = `https://${orgValue}`;
                }
            }
            if (key === "mailto") {
                key = "url";
            }
            link[key] = e.target.value;
            if (key === "module") {
                link.url = "";
                link.item_id = "";
                getLinkItems(link);
            }
        } ;

        watch(
            () => componentData.links,
            (links) => {
                input.value = JSON.stringify(links);
                emit('value-change', {
                    name: props.name,
                    value: input.value
                });
            },
            {
                immediate: true,
                deep: true
            }
        );

        const handleDelete = (link) => {
            const index = componentData.links.indexOf(link);
            if (index > -1) {
                componentData.links.splice(index, 1);
            }
            if (!componentData.links.length) {
                componentData.links = [
                    {...defaultLink}
                ];
            }
        }
        const handleNew = () => {
            componentData.links.push({...defaultLink});
        }

        const items = (link) => {
            return computed(() => {
                const options = {};
                componentData.items?.[link.module]?.forEach(item => {
                    options[item.id] = item.title;
                });
                return options;
            }).value;
        }

        return {
            trans,
            input,
            module,
            inputRef,
            moduleRef,
            error,
            componentData,
            resolveProps,
            validate,
            required,

            handleChange,
            handleDelete,
            handleNew,

            items
        };
    },
}
</script>
