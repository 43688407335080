<template>
    <table class="table" cellspacing="0" cellpadding="0">
        <thead>
        <tr>
            <th colspan="2"></th>
            <th width="100">{{ trans("Test") }}</th>
            <th width="100">{{ trans("Staging") }}</th>
            <th width="100">{{ trans("Production") }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in projects()" :set="(production = resolveHosting(item, 'production'))">
            <td class="td_title">
                <a class="titlelink" :href="`https://bitbucket.org/studioibizz/${item.data.bitbucket_slug}/src`" target="_blank">
                    {{ item._meta.name }}
                    <span class="excerptbutton" @click.prevent="toggleExcerpt">
                        <Icon name="chevron" />
                    </span>
                    <span class="deletebutton" @click.prevent="handleDelete($event, item.data.id, 'project')">
                        <Icon name="delete" />
                    </span>
                    <span class="editbutton" @click.prevent="handleEdit(item, 'projectEdit')">
                        <Icon name="edit" />
                    </span>
                </a>
                <span v-if="item.data.bitbucket_autodeploy" class="tag autodeploy">{{ trans("Autodeploy") }}</span>
                <span class="tag wingzz" :class="resolveVersionClasses(item)" v-if="item.data.wingzz_version">{{ item.data.wingzz_version }}</span>
                <span class="tag php" v-if="production && production.php_version">{{ production.php_version }}</span>
                <div class="excerpt">
                    <ul class="block-grid excerptlist">
                        <li>
                            <label>{{ trans("Aangemaakt door") }}:</label>
                            <var>{{ item.data.creation_user ?? trans("Onbekend") }}</var>
                        </li>
                        <li>
                            <label>{{ trans("Aangemaakt op") }}:</label>
                            <var>{{ item.data.creation_date ?? trans("Onbekend") }}</var>
                        </li>
                    </ul>
                </div>
            </td>

            <td class="td_tools" nowrap>
                <nav class="iconav">
                    <a class="bitbucketbutton" :href="`https://bitbucket.org/studioibizz/${item.data.bitbucket_slug}/pipelines`" target="_blank" :title="trans('Open Bitbucket Pipelines')">
                        <Icon name="bitbucket" />
                    </a>
                    <button type="button" class="jirabutton" @click.prevent="handleJiraClick(item)" :title="trans('Zoek naar JIRA projecten')">
                        <Icon name="jira" />
                    </button>
                </nav>
            </td>
            <td class="td_test" :data-title="trans('Test')"  nowrap :set="test = resolveHosting(item, 'test')">
                <nav v-if="test?.domain_name">
                    <a class="more-inline show test" :href="`//${test.domain_name}`" target="_blank">
                        <span class="title">🔗 {{ trans("Openen") }}</span>
                    </a>
                    <button type="button" class="more-inline ftp" :key="test.id" @click.prevent="handleHostingRequest(item, resolveHosting(item, 'test'))">
                        <span class="title">📄 {{ trans("Login") }}</span>
                    </button>
                    <button type="button" class="more-inline alias" :key="test.id" @click.prevent="handleAliasOverview(item, 'test')">
                        <span class="title">🔎 {{ trans("Alias beheren") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button type="button" class="more-inline new test" @click.prevent="handleHostingAdd(item, 'test')">
                        <span class="title">➕ {{ trans("Aanmaken")}}</span>
                    </button>
                </nav>
            </td>
            <td class="td_staging" :data-title="trans('Staging')"  nowrap :set="staging = resolveHosting(item, 'staging')">
                <nav v-if="staging?.domain_name">
                    <a class="more-inline show staging" :href="`//${staging?.domain_name}`" target="_blank">
                        <span class="title">🔗 {{ trans("Openen") }}</span>
                    </a>
                    <button type="button" class="more-inline ftp" :key="staging.id" @click.prevent="handleHostingRequest(item, resolveHosting(item, 'staging'))">
                        <span class="title">📄 {{ trans("Login") }}</span>
                    </button>
                    <button type="button" class="more-inline alias" :key="staging.id" @click.prevent="handleAliasOverview(item, 'staging')">
                        <span class="title">🔎 {{ trans("Alias beheren") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button type="button" class="more-inline new staging" @click.prevent="handleHostingAdd(item, 'staging')">
                        <span class="title">➕ {{ trans("Aanmaken") }}</span>
                    </button>
                </nav>
            </td>
            <td class="td_production" :data-title="trans('Production')"  nowrap>
                <nav v-if="production?.domain_name" :set="state = resolvePublicationType(production)">
                    <a class="more-inline show production" :href="`//${production.domain_name}`" target="_blank">
                        <span class="indicator" :class="state" :title="state"></span>
                        <span class="title">{{ trans("Openen") }}</span>
                    </a>
                    <button v-if="production.id" class="more-inline ftp" type="button" :key="production"  @click.prevent="handleHostingRequest(item, resolveHosting(item, 'production'))">
                        <span class="title">📄 {{ trans("Login") }}</span>
                    </button>
                    <button type="button" class="more-inline alias" :key="production.id" @click.prevent="handleAliasOverview(item, 'production')">
                        <span class="title">🔎 {{ trans("Alias beheren") }}</span>
                    </button>
                </nav>
                <nav v-else>
                    <button type="button" class="more-inline new production" @click.prevent="handleHostingAdd(item, 'production')">
                        <span class="title">➕ {{ trans("Aanmaken") }}</span>
                    </button>
                </nav>
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
import {inject} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";

import { getQuery } from "helpers/_structure";

export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    components: {
        Icon
    },
    setup(props) {
        const projects = () => {
            // Filter out deleted projects
            return props.data.filter(item => item.data.state !== "Delete");
        };

        const resolveVersion = (item) => {
            const result = {
                version: item.data.wingzz_version,
                simplified: item.data.wingzz_version
            };
            if (item.data.wingzz_version) {
                result.simplified = result.simplified.substr(0, result.simplified.indexOf("."));
            }
            return result;
        };
        const resolveVersionClasses = (item) => {
            const version = resolveVersion(item);
            return {
                [version.simplified]: true
            }
        };

        const resolveHosting = (item, type = "production") => {
            if (!item.extra.hosting[type]?.[0]) {
                return null;
            }
            return item.extra.hosting[type]?.[0];
        };

        const resolvePublicationType = (hosting) => {
            return hosting.domain_name.endsWith("ibizz.nl") ? "otap" : "live";
        };

        const handleHostingRequest = inject("handleHostingRequest");
        const handleHostingAdd = inject("handleHostingAdd");
        const handleAliasAdd = inject("handleAliasAdd");
        const handleAliasOverview = inject("handleAliasOverview");

        const toggleExcerpt = (e) => {
            const target = e.currentTarget || e.target;
            target.parentNode.parentNode.classList.toggle("excerpt_open");
        };

        const handleJiraClick = (item) => {
            let companyName = (item.data.customer_name ?? getQuery("search") ?? "").toLowerCase();
            [" website", " webshop", " api"].forEach(key => {
                companyName = companyName.replace(key, "");
            });
            window.open(`https://studioibizz.atlassian.net/jira/projects?contains=${companyName}&selectedCategory=all&selectedProjectType=all&sortKey=name&sortOrder=ASC`, "_blank");
        };

        const handleDelete = inject("handleDelete");
        const handleEdit = inject("handleEdit");

        return {
            trans,

            resolveVersion,
            resolveVersionClasses,

            resolveHosting,
            resolvePublicationType,

            handleHostingRequest,
            handleHostingAdd,
            handleAliasAdd,
            handleAliasOverview,
            handleDelete,
            handleEdit,

            projects,

            toggleExcerpt,

            handleJiraClick
        };
    },
}
</script>