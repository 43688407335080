<template>
    <div class="element HiddenField hidden">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <input
            ref="inputRef"
            type="text"
            class="input"
            readonly
            autocomplete="false"
            :id="`field_${this.id}`"
            :name="this.name"
            v-model="input"
        />
    </div>
</template>

<script>
import {inject, onMounted, ref, watch} from "vue";

// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";

import { observeElement } from "helpers/_observer";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props, { emit }) {
		const inputRef = ref();
        const input = ref("");
        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

		const killswitch = inject("killswitch");
		onMounted(() => {
            observeElement(inputRef.value, "value", (oldValue, newValue) => {
				if (props.value == newValue) return;
                console.error("DOM manipulation not allowed", oldValue, newValue);
                killswitch && killswitch();
            });
		});

        return {
			input,
			inputRef
		};
    },
}
</script>