<template>
    <div class="dropdown" :class="this.classes" ref="dropdown">
        <button class="dropdown-toggle" :class="this.button_classes" type="button" @click="handleDropdownClick">
            <slot name="button"></slot>
        </button>
        <div class="dropdown-menu" :class="{show: isDropdownActive, [this.position]: true}">
            <slot name="menu"></slot>
        </div>
    </div>
</template>

<script>
import { defineComponent, defineEmits, ref, watch } from "vue";

export default defineComponent({
    name: "Dropdown",
    props: {
        classes: {
            type: String,
            required: false,
            default: ""
        },
        button_classes: {
            type: String,
            required: false,
            default: ""
        },
        position: {
            type: String,
            required: false,
            default: "left"
        }
    },
    setup(props, context) {
        const dropdown = ref();
        const isDropdownActive = ref(false);
        const catchOutsideClick = (e, dropdown) => {
            // When user clicks menu — do nothing
            if (dropdown === e.target || dropdown.contains(e.target)) return false;
            // When user clicks outside of the menu — close the menu
            if (isDropdownActive.value && dropdown !== e.target && !dropdown.contains(e.target)) return true;
        };
        const handleDropdownClick = (e) => {
            isDropdownActive.value = !isDropdownActive.value;
            const closeListener = (e) => {
                if (catchOutsideClick(e, dropdown.value)) {
                    window.removeEventListener('click', closeListener);
                    isDropdownActive.value = false;
                }
            };
            if (isDropdownActive.value) {
                window.addEventListener('click', closeListener);
            } else {
                window.removeEventListener('click', closeListener);
            }
        };

        watch(
            () => isDropdownActive.value,
            (val) => {
                context.emit(val ? 'open' : 'close')
            }
        );

        return {
            isDropdownActive,
            dropdown,
            handleDropdownClick
        }
    },
});
</script>